import validator from "validator";
import stripEmptyObjectProp from "../../utils/stripEmptyObjectProp";

interface SMS {
   receivers: string | string[];
   message: string;
   groupReceivers: string[];
   everyone: boolean;
}

interface SMSError {
   receivers: string;
   message: string;
}

const validateSMS = (sms: SMS) => {
   const errors = {} as SMSError;

   errors.receivers = !/^\d,?/.test(String(sms.receivers)) ? "Invalid character in recipient list" : "";
   errors.receivers =
      !sms.receivers?.length && !sms.groupReceivers?.length
         ? "No receivers specified. Kindly provide at least one receiver."
         : null;
   errors.receivers = sms.everyone ? "" : errors.receivers;
   errors.message = validator.isEmpty(String(sms.message)) ? "Message is required" : null;

   return stripEmptyObjectProp(errors);
};

export { validateSMS };
