import { useEffect, useState } from "react";
import Pagination from "../../components/Pagination";
import { Loading } from "../../components/ui/loading";
import { ResourceLoadingError } from "../../components/ui/resource-loading-error";
import Workspace from "../../components/workspace";
import branchService from "../../services/branch-service";
import Branches from "./branches";

const BranchesListContainer = ({ search = "", page, setPage }) => {
   const [branches, setBranches] = useState([]);
   const [isLoading, setIsLoading] = useState(true);
   const [errorMessage, setErrorMessage] = useState();
   const [rows] = useState(10);
   const [totalPages, setTotalPages] = useState(1);

   useEffect(() => {
      const loadBranches = async () => {
         setIsLoading(true);
         try {
            const fetchResult = await branchService.getBranches({ page, rows, search });
            setBranches(fetchResult.data);
            setTotalPages(fetchResult.totalPages);
         } catch (error) {
            setErrorMessage(error.message);
         } finally {
            setIsLoading(false);
         }
      };
      loadBranches();
      return () => {
         setErrorMessage(null);
      };
   }, [page, rows, search]);

   if (isLoading) return <Loading />;
   if (errorMessage) return <ResourceLoadingError error={errorMessage} />;

   return (
      <Workspace.ListWrapper>
         <Branches branches={branches} setBranches={setBranches} />
         {branches.length ? <Pagination setPage={setPage} page={page} totalPages={totalPages} /> : ""}
      </Workspace.ListWrapper>
   );
};

export default BranchesListContainer;
