import { useDisclosure } from "@chakra-ui/react";
import { useState } from "react";
import EmptyList from "../../../components/ui/EmptyList";
import Permission from "./Permission";
import { PermissionForm } from "./PermissionForm";

const Permissions = ({ permissionsList = [] }) => {
   const [selected, setSelected] = useState(null);
   const formModal = useDisclosure();
   if (!permissionsList.length) return <EmptyList message="Permissions would be listed here" />;

   return (
      <>
         {formModal.isOpen ? <PermissionForm modal={formModal} id={selected?.id} setSelected={setSelected} /> : null}
         <ul>
            {permissionsList.map((_permission, key) => (
               <Permission formModal={formModal} permission={_permission} key={key} setSelected={setSelected} />
            ))}
         </ul>
      </>
   );
};

export default Permissions;
