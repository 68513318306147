import { User, UserBranch } from "../../types";

type SidebarUserProp = User & { branch: UserBranch };

const SidebarUser = ({ user }: { user: SidebarUserProp }) => {
   const { roleName = "", firstName = "", lastName = "", branch } = user;

   return (
      <div className="user | flex items-center py-3 px-4 font-semibold rounded-2xl bg-bgPrimary">
         <div className="user__details | grid">
            <p className="user__name | pb-0">{firstName + " " + lastName}</p>
            <p className="user__role | text-xs text-gray-400">{roleName}</p>
            <p className="user__branch | text-xs">{branch?.branchName || null}</p>
         </div>
      </div>
   );
};

export default SidebarUser;
