const getAccessToken = () => {
  return localStorage.getItem("accessToken") || null;
};

const setAccessToken = (token) => {
  localStorage.setItem("accessToken", token);
};

const removeAccessToken = () => {
  localStorage.removeItem("accessToken");
};

const tokenService = {
  getAccessToken,
  setAccessToken,
  removeAccessToken,
};

export default tokenService;
