import validator from "validator";

export default function validateDate(date = "", { optional } = { optional: false }): string {
  const error = [];

  if (!optional || date) {
    if (validator.isEmpty(date)) {
      error.push("Date is required");
    } else if (!validator.isDate(date)) {
      error.push("Date format not allowed. Date should take the format YYYY-MM-DD");
    }
  }
  return error.join(" ");
}
