import { BiPencil } from "react-icons/bi";
import { PaddedIcon } from "../../components/ui/button/Icon";
import Workspace from "../../components/workspace";

const Service = ({ service, formModal, setSelected }) => {
   return (
      <Workspace.ItemWrapper
         onClick={() => {
            setSelected(service);
            formModal.onOpen();
         }}>
         <div className="flex justify-between items-center px-4 py-5">
            <input type="checkbox" className="me-2 md:me-4" onClick={(e) => e.stopPropagation()} />
            <div className="mx-2 w-full overflow-hidden overflow-ellipsis">
               <p className="font-bold">{`${service.serviceDate} | ${service.name}`}</p>
               <small className="">
                  <b>Title: </b>
                  <i>{service.sermonTitle}</i>
               </small>
            </div>

            <small className="w-1/12 mx-1" title="Branch">
               {service.branchCode}
            </small>

            <small className="w-2/12 md:w-1/12" title="Start time">
               {service.startTime}
            </small>
            <small className="w-2/12 md:w-1/12" title="Stop time">
               {service.stopTime}
            </small>

            <Workspace.ItemActionsWrapper>
               <PaddedIcon variant="primary" aria-label="edit">
                  <BiPencil className="text-lg" />
               </PaddedIcon>
            </Workspace.ItemActionsWrapper>
         </div>
      </Workspace.ItemWrapper>
   );
};

export default Service;
