import validator from "validator";

export default function validateNewPassword(newPassword = ""): string {
  const error = [];

  if (validator.isEmpty(newPassword)) {
    error.push("New password is required");
  } else if (!/^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(newPassword)) {
    error.push(
      "Password must contain at least one special character, one number, one upper case and lower case letters"
    );
  }

  return error.join(" ");
}
