import { BiPencil, BiTrashAlt } from "react-icons/bi";
import { Button } from "../../../components/ui/button/Button";
import { PaddedIcon } from "../../../components/ui/button/Icon";
import Workspace from "../../../components/workspace";
import { useFeatureFlag } from "../../../state/featureFlagContext";

const Role = ({ role, formModal, setSelectedRole }) => {
   const { displayRoleDeleteButton } = useFeatureFlag();

   return (
      <Workspace.ItemWrapper
         onClick={() => {
            setSelectedRole(role);
            formModal.onOpen();
         }}>
         <div className="flex justify-between items-center px-4 py-5">
            <input type="checkbox" className="md:me-4" onClick={(e) => e.stopPropagation()} />
            <p
               className="mx-2 w-full font-bold overflow-hidden overflow-ellipsis"
               title="Role name">{`${role.name}`}</p>

            <p className="role__role | w-1/2 md:w-4/12 text-sm">{role.description}</p>
            <Workspace.ItemActionsWrapper>
               <PaddedIcon variant="primary" aria-label={`Edit ${role.name}`}>
                  <BiPencil className="text-lg" />
               </PaddedIcon>
               {displayRoleDeleteButton ? (
                  <Button
                     variant="danger"
                     title="Delete role"
                     aria-label="Delete role"
                     onClick={(e) => {
                        e.stopPropagation();
                     }}>
                     <BiTrashAlt />
                  </Button>
               ) : null}
            </Workspace.ItemActionsWrapper>
         </div>
      </Workspace.ItemWrapper>
   );
};

export default Role;
