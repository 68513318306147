import { Menu, MenuItem, MenuList } from "@chakra-ui/react";
import { BiPencil, BiUserCheck, BiUserX } from "react-icons/bi";
import { LiaUserLockSolid } from "react-icons/lia";
import { PaddedIcon } from "../../../components/ui/button/Icon";
import Workspace from "../../../components/workspace";

export const userStatusMap = {
   D: <span className="text-red-600">Disabled</span>,
   E: <span className="text-green-600">Enabled</span>,
   L: "Locked",
};

const User = ({ user, formModal, setSelected, statusModal, setNewStatus }) => {
   return (
      <Workspace.ItemWrapper
         onClick={() => {
            setSelected(user);
            formModal.onOpen();
         }}>
         <div className="flex justify-between items-center px-4 py-5">
            <input type="checkbox" className="me-2 md:me-4" onClick={(e) => e.stopPropagation()} />
            <div className="workspace__avatar w-3/12 md:w-1/12 aspect-square rounded-xl"></div>
            <p className="mx-2 w-full font-bold overflow-hidden overflow-ellipsis" title="User name">{`${user.firstName} ${user.lastName}`}</p>

            <div className="w-4/12 md:w-2/12 font-semibold">{userStatusMap[user.status] || ""}</div>

            <Workspace.ItemActionsWrapper>
               <PaddedIcon variant="primary" aria-label="edit">
                  <BiPencil className="text-base" />
               </PaddedIcon>

               <Menu>
                  <Workspace.ItemMenuButton />
                  <MenuList className="!py-4">
                     {user.status === "E" ? (
                        <MenuItem
                           onClick={(e) => {
                              e.stopPropagation();
                              setSelected(user);
                              setNewStatus("D");
                              statusModal.onOpen();
                           }}>
                           <span className="flex text-red-600">
                              <BiUserX className=" text-xl me-2" /> Disable
                           </span>
                        </MenuItem>
                     ) : user.status !== "L" ? (
                        <MenuItem
                           onClick={(e) => {
                              e.stopPropagation();
                              setSelected(user);
                              setNewStatus("E");
                              statusModal.onOpen();
                           }}>
                           <BiUserCheck className=" text-xl me-2" /> Enable
                        </MenuItem>
                     ) : null}

                     {user.status === "E" ? (
                        <MenuItem
                           onClick={(e) => {
                              e.stopPropagation();
                              setSelected(user);
                              setNewStatus("L");
                              statusModal.onOpen();
                           }}>
                           <LiaUserLockSolid className=" text-xl me-2" /> Lock
                        </MenuItem>
                     ) : null}
                     {user.status === "L" ? (
                        <MenuItem
                           onClick={(e) => {
                              e.stopPropagation();
                              setSelected(user);
                              setNewStatus("U");
                              statusModal.onOpen();
                           }}>
                           <BiUserCheck className=" text-xl me-2" /> Unlock
                        </MenuItem>
                     ) : null}
                  </MenuList>
               </Menu>
            </Workspace.ItemActionsWrapper>
         </div>
      </Workspace.ItemWrapper>
   );
};

export default User;
