import { endpoints } from "../../config/endpoints";
import { axiosPrivateFetch } from "../../services/fetch-service";

const getEvents = async ({ page, rows }) => {
  const url = endpoints.liveEvents;
  const options = { params: { page, rows } };
  return axiosPrivateFetch.get(url, options);
};
const getEventQuestions = async ({ eventAlias, page, rows }) => {
  const url = `${endpoints.liveEvents}/${eventAlias}/questions`;
  const options = { params: { page, rows } };
  return axiosPrivateFetch.get(url, options);
};

async function getEvent(id) {
  return axiosPrivateFetch.get(`${endpoints.liveEvents}/${id}`);
}
const getEventByAlias = async (alias: String) => {
  return axiosPrivateFetch.get(`${endpoints.liveEvents}/${alias}`);
};
type UpdateQuestionStatusProp = {
  eventAlias: String;
  questionId: String;
  status: "READ" | "UNREAD" | "IGNORED";
};

const updateQuestionStatus = async ({
  eventAlias,
  questionId,
  status,
}: UpdateQuestionStatusProp) => {
  return axiosPrivateFetch.put(`${endpoints.liveEvents}/${eventAlias}/questions/${questionId}`, {
    status,
  });
};

const liveEventsService = {
  getEvents,
  getEvent,
  getEventQuestions,
  getEventByAlias,
  updateQuestionStatus,
};

export { liveEventsService };
