import { useDisclosure } from "@chakra-ui/react";
import { useState } from "react";
import { BiPlus } from "react-icons/bi";
import { Button } from "../../../components/ui/button/Button";
import Workspace from "../../../components/workspace";
import GroupTypeListContainer from "./group-list-container";
import { GroupTypeForm } from "./group-type-form";

const GroupTypeManagement = () => {
   const [searchQuery, setSearchQuery] = useState("");
   const formModal = useDisclosure();

   return (
      <>
         <GroupTypeForm modal={formModal} />
         <Workspace.TopBar heading="Group Type Management" />
         <Workspace.WorkspaceActions>
            <Button variant="primary" onClick={formModal.onOpen}>
               <span className="inline-flex items-center gap-2">
                  <BiPlus />
                  Create new group type
               </span>
            </Button>
         </Workspace.WorkspaceActions>
         <Workspace.ComponentWrapper>
            <Workspace.WorkspaceHeader>
               <Workspace.WorkspaceHeaderWrapper>
                  <Workspace.WorkspaceSubHeading>Group Types</Workspace.WorkspaceSubHeading>
                  <Workspace.WorkspaceHeaderSearch>
                     <Workspace.SearchForm onSubmit={setSearchQuery} />
                  </Workspace.WorkspaceHeaderSearch>
               </Workspace.WorkspaceHeaderWrapper>
            </Workspace.WorkspaceHeader>
            <GroupTypeListContainer searchQuery={searchQuery} />
         </Workspace.ComponentWrapper>
      </>
   );
};

export default GroupTypeManagement;
