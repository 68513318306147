import { useState } from "react";

import { useNavigate } from "react-router";

import SuccessNotification from "../../components/ui/SuccessNotification";
import validateSecurityForm from "../../utils/validateSecurityForm";

import { Form, Formik } from "formik";
import { Button } from "../../components/ui/button/Button";
import { TextInput } from "../../components/ui/form";
import Workspace from "../../components/workspace";
import authService from "../../services/auth-service";
import profileService from "../../services/profile-service";
import "../../styles/settings.css";

const Security = () => {
   const [isSubmitting, setIsSubmitting] = useState(false);
   const [submitError, setSubmitError] = useState();
   const [successNotificationText, setSuccessNotificationText] = useState("");

   const navigate = useNavigate();
   const { id } = authService.getUserInfo();

   const handleSubmit = async (formValues) => {
      setIsSubmitting(true);

      setSubmitError("");
      try {
         const submitResult = await profileService.submitPasswordChange({
            passwords: formValues,
            id,
         });
         setSuccessNotificationText(submitResult.message);
      } catch (error) {
         setSubmitError(error.message);
      } finally {
         setIsSubmitting(false);
      }
   };
   if (successNotificationText)
      return <SuccessNotification message={successNotificationText} action={() => navigate("/settings/profile")} />;

   return (
      <div className="change-password | ">
         <header className="mb-4 text-secondary">
            <h2>Change password</h2>
         </header>
         <div className="mb-5">
            {submitError && <Workspace.FormSubmitError>{submitError}</Workspace.FormSubmitError>}
         </div>
         <Formik
            initialValues={{ newPassword: "", confirmNewPassword: "", currentPassword: "" }}
            onSubmit={handleSubmit}
            validate={validateSecurityForm}>
            <Form className="grid gap-6">
               <TextInput
                  type="password"
                  label="Current password"
                  id="currentPassword"
                  name="currentPassword"
                  placeholder="Enter current password here"
                  required
               />
               <TextInput
                  type="password"
                  label="New password"
                  id="newPassword"
                  name="newPassword"
                  placeholder="Enter new password here"
                  // required
               />
               <TextInput
                  type="password"
                  label="Re-enter your new password"
                  id="confirmNewPassword"
                  name="confirmNewPassword"
                  placeholder="Enter new password again"
                  // required
               />

               <div className="mb-3 flex justify-end gap-2">
                  <Button variant="secondary" type="reset" onClick={() => navigate("/dashboard")}>
                     Cancel
                  </Button>
                  <Button variant="primary" type="submit" className="btn btn-primary" disabled={isSubmitting}>
                     Change password
                  </Button>
               </div>
            </Form>
         </Formik>
      </div>
   );
};

export default Security;
