import { endpoints } from "../config/endpoints";
import { axiosPrivateFetch } from "./fetch-service";

function getServices({ page, rows }) {
  const url = endpoints.services;
  const options = { params: { page, rows } };
  return axiosPrivateFetch.get(url, options);
}

function getService(id) {
  return axiosPrivateFetch.get(`${endpoints.services}/${id}`);
}

function submitServiceMaintenance({ service, editOrCreate }) {
  const options =
    editOrCreate === "edit"
      ? {
          method: "PUT",
          data: service,
        }
      : { method: "POST", data: service };

  return axiosPrivateFetch(endpoints.services, options);
}

const serviceService = Object.freeze({
  getServices,
  getService,
  submitServiceMaintenance,
});

export default serviceService;
