import { Profile, ProfileValidationError } from "../types";
import stripEmptyObjectProp from "./stripEmptyObjectProp";
import validateEmail from "./validators/validateEmail";
import validateName from "./validators/validateName";

export default function validateProfile(profile: Profile) {
  let errors = {} as ProfileValidationError;
  errors.firstName = validateName(profile.firstName, "First name");
  errors.lastName = validateName(profile.lastName, "Last name");
  errors.email = validateEmail(profile.email);

  return stripEmptyObjectProp(errors);
}
