import { Link } from "react-router-dom";

type ButtonVariants = "primary" | "secondary" | "danger" | "outline-secondary" | "success";

const getVariantClassName = (variant: ButtonVariants) => {
   let variantClassNames: string;
   switch (variant) {
      case "primary":
         variantClassNames = " bg-[--clr-primary] border-[--clr-primary] text-[--clr-white]";
         break;
      case "secondary":
         variantClassNames = " bg-[--clr-secondary] border-[--clr-secondary] text-[--clr-white]";
         break;
      case "success":
         variantClassNames = " bg-bgSuccess border-success text-success";
         break;
      case "danger":
         variantClassNames = " bg-red-500 border-red-500 hover:bg-red-600 text-[--clr-white]";
         break;
      case "outline-secondary":
         variantClassNames = " border-[--clr-secondary] border-2 hover:bg-[--clr-secondary] hover:text-[--clr-white]";
         break;
      default:
         variantClassNames = " ";
         break;
   }

   return variantClassNames;
};

type ButtonProps = React.ComponentProps<"button"> & { variant?: ButtonVariants } & {
   innerRef?: React.Ref<HTMLButtonElement> | null;
};

type LinkButtonProps = React.ComponentProps<"a"> & { variant?: ButtonVariants } & {
   to: string;
   innerRef?: React.Ref<HTMLAnchorElement> | null;
};

const DEFAULT_CLASS_NAMES =
   " flex items-center justify-center border inline-block text-center select-none rounded-md px-6 py-[0.7rem] text-sm";

const Button = ({ children, className = "", variant, innerRef, ...rest }: ButtonProps) => {
   className = className + DEFAULT_CLASS_NAMES + getVariantClassName(variant);

   return (
      <button className={`${className}`} ref={innerRef} {...rest}>
         {children}
      </button>
   );
};

const LinkButton = ({ children, className = "", to, variant, innerRef, ...rest }: LinkButtonProps) => {
   className = className + DEFAULT_CLASS_NAMES + getVariantClassName(variant);

   return (
      <Link className={`${className}`} to={to} ref={innerRef} {...rest}>
         {children}
      </Link>
   );
};

export { Button, LinkButton };
