import { NavLink } from "react-router-dom";

export function NavItem({ children, button = false, ...rest }) {
   const activeLinkClassNames = "nav__link | text-[#444] rounded-lg flex items-center py-2 px-3 nav__link-active";
   const inactiveLinkClassNames = "nav__link flex py-2 px-3 items-center";
   const activeButtonLinkClassNames = "btn btn-line-around active-tabs bg-primary";
   const inactiveButtonLinkClassNames = "btn btn-line-around";
   return (
      <NavLink
         className={({ isActive }) => {
            let returnedClassNames = "";
            if (button) {
               returnedClassNames = isActive ? activeButtonLinkClassNames : inactiveButtonLinkClassNames;
            }
            if (!button) {
               returnedClassNames = isActive ? activeLinkClassNames : inactiveLinkClassNames;
            }
            return returnedClassNames;
         }}
         {...rest}>
         {children}
      </NavLink>
   );
}

const NavItemV1 = ({ children, ...rest }) => {
   return (
      <NavLink
         className={({ isActive }) => {
            return `nav__link | flex items-center p-3 rounded-lg ${
               isActive ? "text-primary bg-bgPrimaryLight nav__link-active" : ""
            }`;
         }}
         {...rest}>
         {children}
      </NavLink>
   );
};

function NavItemWithIcon({ children, to, ...rest }) {
   return (
      <NavItemV1 to={to} {...rest}>
         {children}
      </NavItemV1>
   );
}

export { NavItemWithIcon };
