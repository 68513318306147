import validator from "validator";

export default function validateOccupation(
  occupation = "",
  { optional } = { optional: false }
): string {
  const error = [];

  const MIN = 3;
  const MAX = 40;

  if (!optional || occupation) {
    if (!validator.isLength(occupation, { min: MIN, max: MAX }))
      error.push(`Only ${MIN}-${MAX} characters is allowed for occupation`);

    if (!validator.isAlpha(occupation, "en-GB", { ignore: " -/" }))
      error.push(`Only alphabets, space, forward slash, and hyphen are allowed for occupation`);
  }

  return error.join(" ");
}
