import { useEffect, useState } from "react";
import Pagination from "../../components/Pagination";
import { Loading } from "../../components/ui/loading";
import { ResourceLoadingError } from "../../components/ui/resource-loading-error";
import Workspace from "../../components/workspace";
import attendanceService from "../../services/attendance-service";
import Attendances from "./attendances.jsx";

export function AttendanceListContainer() {
   const [isLoading, setIsLoading] = useState(true);
   const [errorMessage, setErrorMessage] = useState("");
   const [attendances, setAttendances] = useState([]);
   const [page, setPage] = useState(1);
   const [rows] = useState(10);
   const [totalPages, setTotalPages] = useState(1);

   useEffect(() => {
      async function loadAttendances() {
         setIsLoading(true);
         try {
            const fetchResult = await attendanceService.getAttendances({ page, rows });
            setAttendances(fetchResult.data);
            setTotalPages(fetchResult.totalPages);
         } catch (error) {
            setErrorMessage(error.message);
         } finally {
            setIsLoading(false);
         }
      }
      loadAttendances();

      return () => {
         setErrorMessage(null);
      };
   }, [page, rows]);

   if (isLoading) return <Loading />;
   if (errorMessage) return <ResourceLoadingError error={errorMessage} />;

   return (
      <Workspace.ListWrapper>
         <Attendances attendanceList={attendances} />
         {attendances.length ? <Pagination setPage={setPage} page={page} totalPages={totalPages} /> : null}
      </Workspace.ListWrapper>
   );
}
