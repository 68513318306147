import { endpoints } from "../config/endpoints";
import { axiosFetch } from "./fetch-service";
import tokenService from "./token-service";

function login({ email, password }) {
  return axiosFetch.post(endpoints.login, { email, password });
}

function logout() {
  tokenService.removeAccessToken();
  return axiosFetch.post(endpoints.logout);
}

function getTokenPayload(token: string) {
  if (!token) return;
  const encodedPayload = token.split(".")[1];

  return encodedPayload ? JSON.parse(window.atob(encodedPayload)) : {};
}

function getUserInfo() {
  const accessToken = tokenService.getAccessToken();
  return getTokenPayload(accessToken)?.user || {};
}

function isLoggedIn() {
  const accessToken = tokenService.getAccessToken();

  return getTokenPayload(accessToken)?.exp * 1000 > Date.now() || false;
}

const authService = {
  login,
  logout,
  getUserInfo,
  isLoggedIn,
};

export default authService;
