import { useDisclosure } from "@chakra-ui/react";
import { useState } from "react";
import EmptyList from "../../../components/ui/EmptyList";
import { DeleteMemberDialog } from "./delete-member";
import Member from "./member";
import MemberForm from "./member-form.jsx";
import { SendSMSToMember } from "./send-sms-to-member";

const Members = ({ membersList = [], setMembers }) => {
   const [selected, setSelected] = useState();
   const formModal = useDisclosure();
   const smsModal = useDisclosure();
   const deleteMemberDialog = useDisclosure();

   if (!membersList?.length) return <EmptyList message="Members would be listed here" />;
   return (
      <>
         <DeleteMemberDialog modal={deleteMemberDialog} memberId={selected?.id} setMembers={setMembers} />
         <SendSMSToMember modal={smsModal} member={selected} />
         <MemberForm id={selected?.id} modal={formModal} setSelected={setSelected} />
         <ul>
            {membersList.map((_member, key) => (
               <Member
                  member={_member}
                  key={key}
                  smsModal={smsModal}
                  deleteMemberDialog={deleteMemberDialog}
                  setSelected={setSelected}
                  formModal={formModal}
               />
            ))}
         </ul>
      </>
   );
};

export { Members };
