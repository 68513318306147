import { endpoints } from "../config/endpoints";
import { axiosPrivateFetch } from "./fetch-service";

function getRoles({ findAll, page, rows, searchQuery }) {
   const url = findAll ? `${endpoints.roles}/all` : `${endpoints.roles}`;
   const options = !findAll ? { params: { page, rows, search: searchQuery } } : null;
   return axiosPrivateFetch.get(url, options);
}

function getRole({ id }) {
   return axiosPrivateFetch.get(`${endpoints.roles}/${id}`);
}
function deleteRole({ roleID }) {
   const options = {
      method: "DELETE",
   };
   return axiosPrivateFetch.get(`${endpoints.roles}/${roleID}`, options);
}

function submitRoleMaintenance({ role, editOrCreate }) {
   const options =
      editOrCreate === "edit"
         ? {
              method: "PUT",
              data: role,
              url: `${endpoints.roles}/${role.id}`,
           }
         : { method: "POST", data: role, url: `${endpoints.roles}` };

   return axiosPrivateFetch(options);
}

const roleService = { getRoles, getRole, deleteRole, submitRoleMaintenance };

export default roleService;
