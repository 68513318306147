import { Menu, MenuItem, MenuList } from "@chakra-ui/react";
import { BiEnvelope, BiPencil, BiTrashAlt } from "react-icons/bi";
import { ComponentRequireRole } from "../../../components/ComponentRequireRole";
import { PaddedIcon } from "../../../components/ui/button/Icon";
import Workspace from "../../../components/workspace";
import { roleMap } from "../../../config/roleMapping";

const Member = ({ member, formModal, smsModal, deleteMemberDialog, setSelected }) => {
   return (
      <Workspace.ItemWrapper
         onClick={() => {
            setSelected(member);
            formModal.onOpen();
         }}>
         <div className="flex justify-between items-center px-4 py-5">
            <input type="checkbox" className="me-2 md:me-4" onClick={(e) => e.stopPropagation()} />
            <div className="mx-2 w-full overflow-hidden overflow-ellipsis">
               <p className="font-bold" title="Member name">{`${member.firstName} ${member.lastName}`}</p>
               <small className="member-record__phone" title="Member phone">{`${member.phoneNo || ""}`}</small>
            </div>
            <p className="role__role w-4/12 mx-1">{member.membershipLevel}</p>
            <p className="role__role w-1/12">{member.branchCode}</p>
            <Workspace.ItemActionsWrapper>
               <PaddedIcon
                  variant="primary"
                  title={`Edit ${member.firstName} ${member.lastName} details`}
                  aria-label="edit">
                  <BiPencil className="text-lg" />
               </PaddedIcon>

               <Menu>
                  <Workspace.ItemMenuButton />
                  <MenuList className="!py-4">
                     <ComponentRequireRole requiredRoles={[roleMap.pastoralCareDirectorate]}>
                        <>
                           {member.phoneNo ? (
                              <MenuItem
                                 className="gap-2 !py-3"
                                 onClick={(e) => {
                                    setSelected(member);
                                    e.stopPropagation();
                                    smsModal.onOpen();
                                 }}>
                                 <BiEnvelope /> Send SMS
                              </MenuItem>
                           ) : (
                              <MenuItem className="gap-2 !py-3 !text-gray-400 cursor-default">
                                 <BiEnvelope /> Send SMS
                              </MenuItem>
                           )}
                           <MenuItem
                              className="flex gap-2 !py-3 !text-red-600"
                              onClick={(e) => {
                                 setSelected(member);
                                 e.stopPropagation();
                                 deleteMemberDialog.onOpen();
                              }}>
                              <BiTrashAlt /> Delete
                           </MenuItem>
                        </>
                     </ComponentRequireRole>
                  </MenuList>
               </Menu>
            </Workspace.ItemActionsWrapper>
         </div>
      </Workspace.ItemWrapper>
   );
};

export default Member;
