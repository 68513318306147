import { useDisclosure } from "@chakra-ui/react";
import { useState } from "react";
import { BiPlus } from "react-icons/bi";
import { Button } from "../../components/ui/button/Button";
import { default as Workspace } from "../../components/workspace";
import OfferingForm from "./OfferingForm";
import OfferingsListContainer from "./OfferingsListContainer";

function OfferingManagementPage() {
   const [searchQuery, setSearchQuery] = useState("");
   const [page, setPage] = useState(1);
   const formModal = useDisclosure();

   return (
      <>
         <OfferingForm modal={formModal} />
         <Workspace.TopBar heading="Offering Management" />
         <Workspace.WorkspaceActions>
            <Button variant="primary" onClick={formModal.onOpen}>
               <span className="inline-flex items-center gap-2">
                  <BiPlus className=" text-lg" />
                  Register offering
               </span>
            </Button>
         </Workspace.WorkspaceActions>
         <Workspace.ComponentWrapper>
            <Workspace.WorkspaceHeader>
               <Workspace.WorkspaceHeaderWrapper>
                  <Workspace.WorkspaceSubHeading>Offerings</Workspace.WorkspaceSubHeading>
                  <Workspace.WorkspaceHeaderSearch>
                     <Workspace.SearchForm
                        onSubmit={(e) => {
                           setPage(1);
                           setSearchQuery(e);
                        }}
                     />
                  </Workspace.WorkspaceHeaderSearch>
               </Workspace.WorkspaceHeaderWrapper>
            </Workspace.WorkspaceHeader>
            <OfferingsListContainer search={searchQuery} page={page} setPage={setPage} />
         </Workspace.ComponentWrapper>
      </>
   );
}

export default OfferingManagementPage;
