import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../../components/ui/button/Button";
import { TextInput } from "../../components/ui/form";
import { Loading } from "../../components/ui/loading";
import { ResourceLoadingError } from "../../components/ui/resource-loading-error";
import SuccessNotification from "../../components/ui/SuccessNotification";
import Workspace from "../../components/workspace";
import authService from "../../services/auth-service";
import profileService from "../../services/profile-service";
import "../../styles/settings.css";
import validateProfile from "../../utils/validateProfile";

const Profile = () => {
   const [isLoading, setIsLoading] = useState(true);
   const [fetchedDetails, setFetchedDetails] = useState();

   const [isSubmitting, setIsSubmitting] = useState(false);
   const [submitError, setSubmitError] = useState();
   const [successNotificationText, setSuccessNotificationText] = useState("");
   const [fetchError, setFetchError] = useState("");

   const navigate = useNavigate();

   const { id } = authService.getUserInfo();

   useEffect(() => {
      const loadProfileDetails = async () => {
         try {
            const fetchResult = await profileService.getProfile(id);
            setFetchedDetails(fetchResult.data);
         } catch (error) {
            setFetchError(error.message);
         } finally {
            setIsLoading(false);
         }
      };

      loadProfileDetails();

      return () => {
         setFetchedDetails({});
         setFetchError("");
         setIsLoading(true);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   const handleSubmit = async (fetchedDetails) => {
      setIsSubmitting(true);

      try {
         const submitResult = await profileService.submitProfileEdit({
            profile: fetchedDetails,
         });
         setSuccessNotificationText(submitResult.message);
      } catch (error) {
         setSubmitError(error.message);
      } finally {
         setIsSubmitting(false);
      }
   };

   if (isLoading) return <Loading />;
   if (successNotificationText)
      return <SuccessNotification message={successNotificationText} action={() => navigate("..", { relative: "path" })} />;
   if (fetchError) return <ResourceLoadingError error={fetchError} />;

   return (
      <>
         {/* <header>
            <h2 className="mb-4 h5 text-secondary">Display picture</h2>
            </header>
            <div className="avatar-section flex items-center mb-5">
            <div className="avatar-section__avatar-container flex me-4">
            {fetchedDetails.displayPicture ? (
               <img
               src={fetchedDetails?.displayPicture}
               alt={fetchedDetails?.firstName + " " + fetchedDetails?.lastName + " DP"}
                  />
               ) : (
                  <div className="default-avatar flex items-center justify-center">
                     <i className="bi bi-person-circle"></i>
                  </div>
               )}
               </div>
               {functionNotImplemented ? null : (
                  <div className="avatar-section__options flex flex-col gap-2">
                  <ButtonV1 variant="primary">
                  {fetchedDetails?.displayPicture ? "Change picture" : "Upload picture"}
                  </ButtonV1>
                  <ButtonV1 variant="danger">Remove picture</ButtonV1>
                  </div>
               )}
            </div> */}
         <div className="profile-details">
            <header className="mb-4 text-secondary">
               <h2>Personal details</h2>
            </header>
            <div className="mb-5">{submitError && <Workspace.FormSubmitError>{submitError}</Workspace.FormSubmitError>}</div>
            <Formik initialValues={fetchedDetails} validate={validateProfile} onSubmit={handleSubmit} enableReinitialize>
               <Form className="grid gap-6">
                  <TextInput
                     type="text"
                     label="First Name"
                     id="firstName"
                     name="firstName"
                     placeholder="Enter first name here"
                     required
                  />
                  <TextInput
                     type="text"
                     label="Last Name"
                     id="lastName"
                     name="lastName"
                     placeholder="Enter last name here"
                     required
                  />
                  <TextInput
                     label="Email"
                     id="email"
                     name="email"
                     type="email"
                     placeholder="someone@example.com"
                     required
                     disabled
                  />

                  <div className="mb-3 flex justify-end gap-2">
                     <Button variant="secondary" type="reset" onClick={() => navigate("/")}>
                        Cancel
                     </Button>
                     <Button variant="primary" type="submit" className="btn btn-primary" disabled={isSubmitting}>
                        Edit profile
                     </Button>
                  </div>
               </Form>
            </Formik>
         </div>
      </>
   );
};

export default Profile;
