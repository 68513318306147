import { endpoints } from "../config/endpoints";
import { axiosPrivateFetch } from "./fetch-service";

function getPermissions({ findAll, page, rows, search }) {
   const url = findAll ? `${endpoints.permissions}/all` : `${endpoints.permissions}`;
   const options = !findAll ? { params: { page, rows, search } } : null;
   return axiosPrivateFetch.get(url, options);
}

function getPermission({ id, signal }) {
   return axiosPrivateFetch.get(`${endpoints.permissions}/${id}`, { signal });
}

function submitPermissionMaintenance({ permission, editOrCreate }) {
   const options =
      editOrCreate === "edit"
         ? {
              method: "PUT",
              data: permission,
              url: `${endpoints.permissions}/${permission.id}`,
           }
         : { method: "POST", data: permission, url: `${endpoints.permissions}` };

   return axiosPrivateFetch(options);
}

const permissionService = { getPermissions, getPermission, submitPermissionMaintenance };

export default permissionService;
