import { Button } from "./button/Button";

const SuccessNotification = ({ message = "", action = () => {} }) => {
   return (
      <div className="success | grid justify-center items-center my-16 text-center">
         <i className="bi bi-check-circle mb-3 text-success text-8xl"></i>
         <p className="mb-4">{message}</p>
         <div className="grid justify-center">
            <Button variant="primary" className="btn btn-primary btn-lg" onClick={action}>
               OK
            </Button>
         </div>
      </div>
   );
};

export default SuccessNotification;
