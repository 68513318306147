import { useNavigate } from "react-router-dom";
import { Button } from "./button/Button";

type ErrorProp = {
   error: string | Error;
   showButton?: boolean;
   onOk?: () => void;
};

const ResourceLoadingError = ({ error = "Unable to fetch resource, please try again later", showButton = true, onOk = () => {} }: ErrorProp) => {
   const navigate = useNavigate();
   return (
      <div className="justify-center items-center grid my-16">
         {typeof error === "string" ? error : error.message}
         {showButton && (
            <div className="mx-auto my-4">
               <Button variant="primary" onClick={() => (onOk ? onOk() : navigate(-1))}>
                  Ok
               </Button>
            </div>
         )}
      </div>
   );
};

export { ResourceLoadingError };
