import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import React from "react";
import * as ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import App from "./App";
import "./index.css";
import ForgotPasswordPage from "./pages/ForgotPassword";
import PageNotFound from "./pages/PageNotFound";
import ResetPasswordPage from "./pages/ResetPassword";
import LoginPage from "./pages/auth/Login";
import { BranchListStateProvider } from "./state/branchListContext";
import { FeatureFlagProvider } from "./state/featureFlagContext";

const root = ReactDOM.createRoot(document.getElementById("root"));

const queryClient = new QueryClient();
root.render(
   <React.StrictMode>
      <BrowserRouter>
         <QueryClientProvider client={queryClient}>
            <BranchListStateProvider>
               <FeatureFlagProvider>
                  <div className="flex min-h-full">
                     <Routes>
                        <Route path="login" element={<LoginPage />} />
                        <Route path="forgot-password" element={<ForgotPasswordPage />} />
                        <Route path="reset-password" element={<ResetPasswordPage />}>
                           <Route path=":tokenID" element={<ResetPasswordPage />} />
                        </Route>
                        <Route path="/*" element={<App />} />
                        <Route path="*" element={<PageNotFound />} />
                     </Routes>
                  </div>
               </FeatureFlagProvider>
            </BranchListStateProvider>
         </QueryClientProvider>
      </BrowserRouter>
   </React.StrictMode>
);
