import { AxiosRequestConfig } from "axios";
import { endpoints } from "../../../config/endpoints";
import { axiosPrivateFetch } from "../../../services/fetch-service";
import { ApiResponse } from "../../../types";

const getGroup = async (groupId: string) => {
   return axiosPrivateFetch.get(`${endpoints.groups}/${groupId}`) as any as ApiResponse;
};
const getGroups = async ({ page, rows, search }) => {
   return axiosPrivateFetch.get(endpoints.groups, { params: { page, rows, search } }) as any as ApiResponse;
};

const getGroupTypes = async () => {
   return axiosPrivateFetch.get(endpoints.groupTypes);
};

const getAllGroups = (signal) => {
   const url = `${endpoints.groups}/all`;
   return axiosPrivateFetch.get(url, { signal });
};

const submitGroupMaintenance = ({ group, editOrCreate }) => {
   const options: AxiosRequestConfig =
      editOrCreate === "edit"
         ? {
              method: "PUT",
           }
         : { method: "POST" };
   options.data = group;

   const url = editOrCreate === "edit" ? `${endpoints.groups}/${group.id}` : endpoints.groups;

   return axiosPrivateFetch(url, options);
};

const deleteGroup = async (groupId: string) => {
   const options = {
      method: "DELETE",
      url: `${endpoints.groups}/${groupId}`,
   };

   return axiosPrivateFetch(options);
};

const getAllGroupMembers = async (groupId) => {
   return axiosPrivateFetch.get(`${endpoints.groups}/${groupId}/members/all`) as any as ApiResponse;
};

const editGroupMembers = async (groupId: string, members: string[]) => {
   const options = { method: "PUT", data: { members }, url: `${endpoints.groups}/${groupId}/members` };

   return axiosPrivateFetch(options) as any as ApiResponse;
};

const groupService = {
   deleteGroup,
   editGroupMembers,
   getAllGroups,
   getGroup,
   getGroups,
   getGroupTypes,
   getAllGroupMembers,
   submitGroupMaintenance,
};

export default groupService;
