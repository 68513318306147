import writeXlsxFile from "write-excel-file";

const DEFAULT_DATA = [{ noData: "" }],
  DEFAULT_HEADERS = ["No Data"],
  DEFAULT_SHEET_NAME = "Sheet 1";

function makeSchema(headers: string[]) {
  return headers.map((_header) => {
    return {
      column: _header,
      type: String,
      value: (data) => data[_header.trim()],
    };
  });
}

async function makeExcelReportFile({
  data = DEFAULT_DATA,
  sheetName = DEFAULT_SHEET_NAME,
  reportName = "",
  headers = DEFAULT_HEADERS,
}: {
  data: object[];
  reportName: string;
  headers: string[];
  sheetName?: string;
}) {
  await writeXlsxFile(data, {
    schema: makeSchema(headers),
    fileName: reportName + ".xlsx",
    headerStyle: {
      fontWeight: "bold",
    },
    sheet: sheetName,
  });
}

export default makeExcelReportFile;
