export default function validateBranchCode(branchCode = "") {
  const error = [];

  if (!branchCode) {
    error.push("Branch code is required");
  } else if (!/^\d{2}$/.test(branchCode) || branchCode === "00") {
    error.push("Branch code not allowed. Branch code should be of format DD.");
  }

  return error.join(" ");
}
