import { User, UserValidationError } from "../types";
import stripEmptyObjectProp from "./stripEmptyObjectProp";
import validateBranchCode from "./validators/validateBranchCode";
import validateEmail from "./validators/validateEmail";
import validateName from "./validators/validateName";
import validateRole from "./validators/validateRole";

export default function validateUser(user: User) {
  let errors = {} as UserValidationError;
  errors.branchCode = validateBranchCode(user.branchCode);
  errors.firstName = validateName(user.firstName, "First name");
  errors.lastName = validateName(user.lastName, "Last name");
  errors.email = validateEmail(user.email);
  errors.roleId = validateRole(user.roleId);

  if (user.status && !["E", "D"].includes(user.status)) errors.status = "Status flag not allowed";

  return stripEmptyObjectProp(errors);
}
