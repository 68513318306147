import { useDisclosure } from "@chakra-ui/react";
import { useState } from "react";
import EmptyList from "../../../components/ui/EmptyList";
import { DeleteGroupDialog } from "./delete-group.jsx";
import { default as Group } from "./group";
import GroupForm from "./group-form.jsx";
import ManageGroupMembers from "./manage-group-members";

const Groups = ({ groups = [], refreshData }) => {
   const [selected, setSelected] = useState<{ id: string }>({ id: "" });
   const formModal = useDisclosure();
   const deleteGroupDialog = useDisclosure();
   const manageGroupModal = useDisclosure();

   if (!groups?.length) return <EmptyList message="Groups would be listed here" />;

   return (
      <>
         {manageGroupModal.isOpen ? <ManageGroupMembers group={selected} modal={manageGroupModal} /> : null}
         <DeleteGroupDialog modal={deleteGroupDialog} groupId={selected?.id} refreshData={refreshData} />
         <GroupForm id={selected?.id} modal={formModal} setSelected={setSelected} />
         <ul>
            {groups.map((_group, key) => (
               <Group
                  group={_group}
                  key={key}
                  deleteGroupDialog={deleteGroupDialog}
                  setSelected={setSelected}
                  formModal={formModal}
                  manageGroupModal={manageGroupModal}
               />
            ))}
         </ul>
      </>
   );
};

export { Groups };
