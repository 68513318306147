import { useDisclosure, useToast } from "@chakra-ui/react";
import { useState } from "react";
import { BiUserPlus } from "react-icons/bi";
import { BsFileEarmarkSpreadsheet } from "react-icons/bs";
import { Button } from "../../../components/ui/button/Button";
import Workspace from "../../../components/workspace";
import MemberForm from "./member-form.jsx";
import MembersListContainer from "./member-list-container";
import memberService from "./member-service";

const MemberManagementPage = () => {
   const [searchQuery, setSearchQuery] = useState("");
   const [page, setPage] = useState(1);
   const formModal = useDisclosure();
   const toast = useToast();

   async function downloadMembersReport() {
      try {
         await memberService.downloadMembers();
      } catch (error) {
         toast({
            description: error.message || error || "Oops! Something went wrong",
            status: "error",
            title: "Download members report",
         });
      }
   }

   return (
      <>
         <MemberForm modal={formModal} />
         <Workspace.TopBar heading="Member Management" />
         <Workspace.WorkspaceActions>
            <Button variant="primary" onClick={formModal.onOpen}>
               <span className="inline-flex items-center gap-2">
                  <BiUserPlus className=" text-lg" />
                  Add new member
               </span>
            </Button>
            <Button variant="secondary" onClick={downloadMembersReport}>
               <span className="inline-block text-xl me-2">
                  <BsFileEarmarkSpreadsheet />
               </span>
               Export
            </Button>
         </Workspace.WorkspaceActions>
         <Workspace.ComponentWrapper>
            <Workspace.WorkspaceHeader>
               <Workspace.WorkspaceHeaderWrapper>
                  <Workspace.WorkspaceSubHeading>Members</Workspace.WorkspaceSubHeading>
                  <Workspace.WorkspaceHeaderSearch>
                     <Workspace.SearchForm
                        onSubmit={(e) => {
                           setPage(1);
                           setSearchQuery(e);
                        }}
                     />
                  </Workspace.WorkspaceHeaderSearch>
               </Workspace.WorkspaceHeaderWrapper>
            </Workspace.WorkspaceHeader>
            <MembersListContainer search={searchQuery} page={page} setPage={setPage} />
         </Workspace.ComponentWrapper>
      </>
   );
};

export default MemberManagementPage;
