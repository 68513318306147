import validator from "validator";

export default function validatePhoneNumber(
  phoneNumber="",
  { optional } = { optional: false }
): string {
  const error = [];

  const min = 8,
    max = 16;

  if (!optional || phoneNumber) {
    if (!validator.isLength(phoneNumber, { min, max }))
      error.push(`Only ${min}-${max} characters is allowed for phone number`);
    if (!validator.isNumeric(phoneNumber)) error.push(`Only numbers are allowed for phone number`);
  }

  return error.join(" ");
}
