import { useDisclosure } from "@chakra-ui/react";
import { useState } from "react";
import EmptyList from "../../components/ui/EmptyList";
import { sortRecordsByObjectProp } from "../../utils/stripEmptyObjectProp";
import Offering from "./Offering";
import OfferingForm from "./OfferingForm";

const Offerings = ({ offeringsList = [] }) => {
   const [selected, setSelected] = useState();
   const formModal = useDisclosure();

   if (!offeringsList.length) return <EmptyList message="Offering records would be listed here" />;
   return (
      <>
         <OfferingForm id={selected?.id} modal={formModal} setSelected={setSelected} />
         <ul>
            {sortRecordsByObjectProp(offeringsList, "offeringDate", { desc: true }).map((_offering, key) => (
               <Offering offering={_offering} key={key} formModal={formModal} setSelected={setSelected} />
            ))}
         </ul>
      </>
   );
};

export default Offerings;
