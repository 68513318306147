import validator from "validator";

const validateFullName = (
   fullName = "",
   { optional, entity, min = 4, max = 100 }: { optional: boolean; entity: string; min?: number; max?: number }
): string => {
   const error = [];

   if (!optional || fullName) {
      if (typeof fullName !== "string") {
         error.push("Invalid " + entity);
         return error.join(" ");
      }
      if (!validator.isLength(fullName, { min, max }))
         error.push(`Only ${min}-${max} characters is allowed for ${entity.toLowerCase()}`);

      if (!validator.isAlpha(fullName, "en-GB", { ignore: " -" }))
         error.push(`Only alphabets, space and hyphen are allowed for ${entity.toLowerCase()}`);
   }

   return error.join(" ");
};

export { validateFullName };
