import { useEffect, useState } from "react";
import Pagination from "../../../components/Pagination.jsx";
import { Loading } from "../../../components/ui/loading";
import { ResourceLoadingError } from "../../../components/ui/resource-loading-error";
import Workspace from "../../../components/workspace/index.jsx";
import memberService from "./member-service";
import { Members } from "./members.jsx";
const MembersListContainer = ({ search = "", page, setPage }) => {
   const [members, setMembers] = useState([]);
   const [isLoading, setIsLoading] = useState(true);
   const [errorMessage, setErrorMessage] = useState();
   const [rows] = useState(10);
   const [totalPages, setTotalPages] = useState(1);

   useEffect(() => {
      const loadMembers = async () => {
         setIsLoading(true);
         try {
            const fetchResult = await memberService.getMembers({
               page,
               rows,
               search,
            });
            setMembers(fetchResult.data);
            setTotalPages(fetchResult.totalPages);
         } catch (error) {
            setErrorMessage(error.message);
         } finally {
            setIsLoading(false);
         }
      };
      loadMembers();
      return () => {
         setErrorMessage(null);
      };
   }, [page, rows, search]);

   if (isLoading) return <Loading />;
   if (errorMessage) return <ResourceLoadingError error={errorMessage} />;

   return (
      <Workspace.ListWrapper>
         <Members membersList={members} setMembers={setMembers} />
         {members.length ? <Pagination setPage={setPage} page={page} totalPages={totalPages} /> : null}
      </Workspace.ListWrapper>
   );
};

export default MembersListContainer;
