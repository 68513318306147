import { Modal, ModalBody, ModalContent, ModalOverlay, useToast } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { Form, Formik } from "formik";
import { useState } from "react";
import { BiChevronLeft, BiChevronRight, BiChevronsLeft, BiChevronsRight } from "react-icons/bi";
import Pagination from "../../../components/Pagination";
import { Button } from "../../../components/ui/button/Button";
import { PaddedIcon } from "../../../components/ui/button/Icon";
import { CheckboxListItem } from "../../../components/ui/form";
import { Loading } from "../../../components/ui/loading";
import { ResourceLoadingError } from "../../../components/ui/resource-loading-error";
import Workspace from "../../../components/workspace";
import memberService from "../members/member-service";
import groupService from "./group.service";

const ManageGroupMembers = ({ group, modal }) => {
   const [page, setPage] = useState(1);
   const [groupMembersPage, setGroupMembersPage] = useState(1);
   const [search, setSearch] = useState("");
   const [rows] = useState(20);
   const [totalPages, setTotalPages] = useState(1);
   const [groupMembersTotalPages, setGroupMembersTotalPages] = useState(1);
   const [successNotificationText, setSuccessNotificationText] = useState("");
   const [submitError, setSubmitError] = useState("");
   const [selectedGroupMembers, setSelectedGroupMembers] = useState([]);
   const [selectedMembers, setSelectedMembers] = useState([]);

   const heading = "Manage Group Members";

   const toast = useToast();

   const fetchMembers = async () => {
      try {
         const fetchResult: AxiosResponse<any> = await memberService.getAllMembers();
         return fetchResult.data;
      } catch (error) {
         toast({ status: "error", description: error.message, title: "Fetching members.", isClosable: true });
         throw error;
      }
   };
   const {
      data: allMembers = [],
      isLoading: isLoadingAllMembers,
      error: isErrorAllMembers,
      isSuccess: isFetchMemberSuccessful,
   } = useQuery({ queryKey: ["all-members", page], queryFn: fetchMembers });

   const fetchGroupMembers = async () => {
      try {
         const fetchResult: AxiosResponse<any> & { total?: number } = await groupService.getAllGroupMembers(group.id);

         setGroupMembersTotalPages(Math.ceil(fetchResult.total / rows));
         return fetchResult.data;
      } catch (error) {
         toast({ status: "error", description: error.message, title: "Fetching group members.", isClosable: true });
         throw error;
      }
   };

   const {
      data: groupMembers,
      isLoading: isLoadingGroupMembers,
      error: isErrorGroupMembers,
   } = useQuery({ queryKey: ["group-members"], queryFn: fetchGroupMembers });

   const reset = () => {
      setSuccessNotificationText(null);
      setSubmitError(null);
   };

   const resetOnClose = () => {
      reset();
      modal.onClose();
   };

   const getMembersId = (members) => members.flatMap((m) => m?.id);

   if (successNotificationText)
      return (
         <Workspace.SuccessNotificationModal
            modal={modal}
            message={successNotificationText}
            callback={() => {
               reset();
            }}
         />
      );

   const handleSave = async (values) => {
      setSubmitError(null);
      try {
         const submitResult: AxiosResponse<any> & { message?: string } = await groupService.editGroupMembers(
            group.id,
            getMembersId(values?.members || [])
         );
         setSuccessNotificationText(submitResult.message);
      } catch (error) {
         setSubmitError(error.message);
      }
   };

   const updateSelectedMember = (e, member) => {
      if (e.target.checked) {
         setSelectedMembers((_prev) => [..._prev, member]);
      } else {
         setSelectedMembers((_prev) => _prev.filter((_m) => _m.id !== member.id));
      }
   };

   const updateSelectedGroupMember = (e, member) => {
      if (e.target.checked) {
         setSelectedGroupMembers((_prev) => [..._prev, member]);
      } else {
         setSelectedGroupMembers((_prev) => _prev.filter((_m) => _m.id !== member.id));
      }
   };

   const startIndex = (groupMembersPage - 1) * rows;
   const endIndex = startIndex + rows;

   return (
      <Modal isOpen={modal.isOpen} onClose={resetOnClose} size="4xl">
         <ModalOverlay />
         <ModalContent className="!rounded-2xl mx-3">
            <Workspace.ModalHeader>
               <Workspace.ModalHeaderWithCloseButton>{heading}</Workspace.ModalHeaderWithCloseButton>
            </Workspace.ModalHeader>
            <ModalBody className="grid">
               <GroupDetails group={group} />
               <Formik initialValues={{ members: groupMembers || [] }} onSubmit={handleSave} enableReinitialize>
                  {({ isSubmitting, values, setFieldValue }) => (
                     <Form>
                        <div className="mt-5 grid grid-cols-11 gap-x-2 text-sm">
                           <ListWrapper className="col-span-5">
                              <ListHeader>All Members</ListHeader>
                              <div className="py-2">
                                 {isLoadingAllMembers ? <Loading /> : null}
                                 {isErrorAllMembers ? <ResourceLoadingError error={isErrorAllMembers.message} /> : null}

                                 <ScrollableList>
                                    {allMembers
                                       .filter((_member) => !Boolean(getMembersId(values.members).includes(_member.id)))
                                       .map((_member) => (
                                          <CheckboxListItem
                                             key={_member.id}
                                             name="allMembers"
                                             value={_member.id}
                                             onChange={(e) => {
                                                updateSelectedMember(e, _member);
                                             }}>
                                             {_member.firstName} {_member.lastName}
                                          </CheckboxListItem>
                                       ))}
                                 </ScrollableList>

                                 {isFetchMemberSuccessful && allMembers?.length < 1 ? (
                                    <p className="p-2">All members would be listed here</p>
                                 ) : null}
                              </div>
                              {allMembers?.length && totalPages > 1 ? (
                                 <div className="my-3 mx-2 text-sm">
                                    <Pagination setPage={setPage} page={page} totalPages={totalPages} />
                                 </div>
                              ) : null}
                           </ListWrapper>
                           <div className="action-buttons | col-span-1 flex flex-col justify-center items-center">
                              <PaddedIcon
                                 type="button"
                                 as="button"
                                 onClick={() => selectedMembers && setFieldValue("members", allMembers)}>
                                 <BiChevronsRight />
                              </PaddedIcon>
                              <PaddedIcon
                                 type="button"
                                 as="button"
                                 onClick={() => {
                                    setFieldValue("members", Array.from(new Set([...values?.members, ...selectedMembers])));
                                 }}>
                                 <BiChevronRight />
                              </PaddedIcon>

                              <PaddedIcon
                                 as="button"
                                 type="button"
                                 onClick={() => {
                                    const groupMembersSet = new Set([...values?.members]);
                                    selectedGroupMembers.forEach((_member) => groupMembersSet.delete(_member));
                                    setFieldValue("members", Array.from(groupMembersSet));
                                 }}>
                                 <BiChevronLeft />
                              </PaddedIcon>
                              <PaddedIcon type="button" onClick={() => setFieldValue("members", [])} as="button">
                                 <BiChevronsLeft />
                              </PaddedIcon>
                           </div>
                           <ListWrapper className="col-span-5">
                              <ListHeader>Group Members</ListHeader>
                              <div className="py-2">
                                 {isLoadingGroupMembers ? <Loading /> : null}
                                 {isErrorGroupMembers ? <ResourceLoadingError error={isErrorGroupMembers.message} /> : null}
                                 {values.members?.length ? (
                                    <ScrollableList>
                                       {values.members.slice(startIndex, endIndex).map((_member) => (
                                          <CheckboxListItem
                                             key={_member.id}
                                             name="members"
                                             value={_member.id}
                                             onChange={(e) => {
                                                updateSelectedGroupMember(e, _member);
                                             }}>
                                             {`${_member.firstName} ${_member.lastName}`}
                                          </CheckboxListItem>
                                       ))}
                                    </ScrollableList>
                                 ) : null}
                                 {!isLoadingGroupMembers && !values.members?.length ? (
                                    <p className="p-2">Group members would be listed here</p>
                                 ) : null}
                              </div>
                              {values?.members?.length && groupMembersTotalPages > 1 ? (
                                 <div className="my-3 mx-2 text-sm">
                                    <Pagination
                                       setPage={setGroupMembersPage}
                                       page={groupMembersPage}
                                       totalPages={groupMembersTotalPages}
                                    />
                                 </div>
                              ) : null}
                           </ListWrapper>
                        </div>

                        {submitError && (
                           <div className="my-2">
                              <Workspace.FormSubmitError>{submitError}</Workspace.FormSubmitError>
                           </div>
                        )}
                        <div className="grid md:grid-flow-col md:justify-end my-6 pt-0 mb-4 gap-3">
                           <Button variant="secondary" onClick={resetOnClose}>
                              Cancel
                           </Button>
                           <Button variant="primary" type="submit">
                              {isSubmitting ? "Saving..." : "Save"}
                           </Button>
                        </div>
                     </Form>
                  )}
               </Formik>
            </ModalBody>
         </ModalContent>
      </Modal>
   );
};

export default ManageGroupMembers;

const ListWrapper = ({ children, className = "" }) => {
   return <div className={`rounded-xl border overflow-hidden ${className}`}>{children}</div>;
};

const ListHeader = ({ children }) => {
   return <header className="bg-gray-200 font-semibold py-3 px-3 border-b">{children}</header>;
};

const ScrollableList = ({ children }) => {
   return <div className="grid my-3 gap-y-1 max-h-[300px] overflow-y-auto">{children}</div>;
};

const GroupDetails = ({ group }) => {
   return (
      <div className="group-details | overflow-hidden my-3 md:w-2/3 bg-gray-100 rounded-2xl text-xs">
         <p className="border-b border-b-gray-300">
            <span className="bg-gray-200 py-2 px-3 text-right font-semibold w-1/2 md:w-1/3 inline-block">Group Name:</span>
            <span className="px-2">{group.name}</span>
         </p>
         <p>
            <span className="bg-gray-200 py-2 px-3 text-right font-semibold w-1/2 md:w-1/3 inline-block">Number of members:</span>
            <span className="px-2">{group.groupMembersCount || 0}</span>
         </p>
      </div>
   );
};
