import { useEffect, useState } from "react";
import Pagination from "../../components/Pagination";
import { Loading } from "../../components/ui/loading";
import { ResourceLoadingError } from "../../components/ui/resource-loading-error";
import Workspace from "../../components/workspace";
import { liveEventsService } from "../liveEvents/live-events.service";
import { LiveEventsList } from "./live-event-list";

const PAGE_SUBHEADING = "List of Events";

function LiveEvents() {
   return (
      <>
         <Workspace.TopBar heading="Live Events" />
         <Workspace.ComponentWrapper>
            <Workspace.WorkspaceHeader>
               <Workspace.WorkspaceHeaderWrapper>
                  <Workspace.WorkspaceSubHeading>{PAGE_SUBHEADING}</Workspace.WorkspaceSubHeading>
               </Workspace.WorkspaceHeaderWrapper>
            </Workspace.WorkspaceHeader>
            <ActiveEvents />
         </Workspace.ComponentWrapper>
      </>
   );
}

export default LiveEvents;

const ActiveEvents = () => {
   const [isLoading, setIsLoading] = useState(true);
   const [errorMessage, setErrorMessage] = useState("");
   const [liveEvents, setLiveEvents] = useState([]);
   const [page, setPage] = useState(1);
   const [rows] = useState(10);
   const [totalPages, setTotalPages] = useState(1);

   useEffect(() => {
      async function loadActiveEvents() {
         setIsLoading(true);
         try {
            const fetchResult = await liveEventsService.getEvents({ page, rows });
            setLiveEvents(fetchResult.data);
            setTotalPages(fetchResult.totalPages);
         } catch (error) {
            setErrorMessage(error.message);
         } finally {
            setIsLoading(false);
         }
      }
      loadActiveEvents();

      return () => {
         setErrorMessage(null);
      };
   }, [page, rows]);

   if (isLoading) return <Loading />;
   if (errorMessage) return <ResourceLoadingError error={errorMessage} />;

   return (
      <div className="pb-4 mb-3">
         <LiveEventsList liveEvents={liveEvents} />
         {liveEvents.length ? <Pagination setPage={setPage} page={page} totalPages={totalPages} /> : ""}
      </div>
   );
};
