import { useDisclosure } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import Pagination from "../../components/Pagination";
import { DangerBadge, PrimaryBadge, SecondaryBadge, SuccessBadge } from "../../components/ui/badge";
import EmptyList from "../../components/ui/EmptyList";
import { Loading } from "../../components/ui/loading";
import { ResourceLoadingError } from "../../components/ui/resource-loading-error";
import Workspace from "../../components/workspace";
import SMSService from "../../services/sms-service";
import { sortRecordsByObjectProp } from "../../utils/stripEmptyObjectProp";
import SMSDetails from "./sms-details";
import SMS from "./sms-record";

const SMSList = ({ page, setPage }) => {
   const [selected, setSelected] = useState({});
   const [SMSHistory, setSMSHistory] = useState([]);
   const [isLoading, setIsLoading] = useState(true);
   const [errorMessage, setErrorMessage] = useState("");
   const [rows] = useState(10);
   const [totalPages, setTotalPages] = useState(1);

   const modal = useDisclosure();

   useEffect(() => {
      async function loadSMSHistory() {
         setIsLoading(true);
         try {
            const fetchResult = await SMSService.getSMSHistory({ page, rows });
            setSMSHistory(fetchResult.data);
            setTotalPages(fetchResult.totalPages);
         } catch (error) {
            setErrorMessage(error.message);
         } finally {
            setIsLoading(false);
         }
      }
      loadSMSHistory();

      return () => {
         setErrorMessage(null);
      };
   }, [page, rows]);

   if (isLoading) return <Loading />;
   if (errorMessage) return <ResourceLoadingError error={errorMessage} />;
   if (!SMSHistory.length) return <EmptyList message="SMS history would be listed here" />;

   return (
      <>
         <SMSDetails modal={modal} sms={selected} SMSStatusBadge={SMSStatusBadge} />
         <Workspace.ListWrapper>
            <ul>
               {sortRecordsByObjectProp(SMSHistory, "id", { desc: true }).map((_sms, key) => (
                  <SMS sms={_sms} key={key} modal={modal} setSelected={setSelected} SMSStatusBadge={SMSStatusBadge} />
               ))}
               {SMSHistory.length ? <Pagination setPage={setPage} page={page} totalPages={totalPages} /> : ""}
            </ul>
         </Workspace.ListWrapper>
      </>
   );
};

export default SMSList;

const SMSStatusBadge = ({ sms }) => {
   if (sms.status === "FAILED") return <DangerBadge>{sms.status}</DangerBadge>;
   if (sms.status === "SENT") return <PrimaryBadge>{sms.status}</PrimaryBadge>;
   if (sms.status === "PENDING") return <SecondaryBadge>{sms.status}</SecondaryBadge>;
   if (sms.status === "DELIVERED") return <SuccessBadge>{sms.status}</SuccessBadge>;
};
