import { Navigate, useLocation } from "react-router-dom";
import { roleMap } from "../config/roleMapping";
import authService from "../services/auth-service";

const RequireRole = ({ roles = [], children }: { roles: Array<string>; children: React.ReactNode }) => {
   const { getUserInfo, isLoggedIn } = authService;
   const location = useLocation();

   // Senior pastor,resident pastors have global access
   roles.push(roleMap.seniorPastor, roleMap.HQResidentPastor);

   const userRole = getUserInfo()?.roleId;

   const userHasRequiredRole = roles?.includes(userRole);

   if (userHasRequiredRole) {
      return children;
   }

   return isLoggedIn() ? (
      <Navigate to="/unauthorized" state={{ from: location }} replace />
   ) : (
      <Navigate to="/login" state={{ from: location }} replace />
   );
};

export { RequireRole };
