import { SimpleEntity, SimpleEntityValidationError } from "../types";
import stripEmptyObjectProp from "./stripEmptyObjectProp";
import validateEntityDescription from "./validators/validateEntityDescription";
import validateEntityName from "./validators/validateEntityName";

export default function validatePermission(permission: SimpleEntity) {
  let errors = {} as SimpleEntityValidationError;
  errors.name = validateEntityName(permission.name, "Permission");
  errors.description = validateEntityDescription(permission.description, {
    optional: false,
    entity: "Permission",
  });

  return stripEmptyObjectProp(errors);
}
