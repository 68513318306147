import { Outlet } from "react-router-dom";
import Workspace from "../../components/workspace";

const SettingsLayout = () => {
   return (
      <>
         <Workspace.TopBar heading="Settings" />
         <Workspace.ComponentWrapper>
            <Workspace.WorkspaceHeader>
               <Workspace.WorkspaceHeaderLinks>
                  <Workspace.WorkspaceLinkTab to="profile" name="Profile" />
                  <Workspace.WorkspaceLinkTab to="security" name="Security" />
               </Workspace.WorkspaceHeaderLinks>
            </Workspace.WorkspaceHeader>
            <div className="w-full md:w-1/2 lg:w-1/2 py-5 px-8">
               <Outlet />
            </div>
         </Workspace.ComponentWrapper>
      </>
   );
};

export default SettingsLayout;
