import { useDisclosure } from "@chakra-ui/react";
import { useState } from "react";
import EmptyList from "../../components/ui/EmptyList";
import { sortRecordsByObjectProp } from "../../utils/stripEmptyObjectProp";
import Service from "./Service";
import ServiceForm from "./ServiceForm";

const Services = ({ serviceList = [], setServices }) => {
   const [selected, setSelected] = useState();
   const formModal = useDisclosure();

   if (!serviceList.length) return <EmptyList message="Service records would be listed here" />;
   return (
      <>
         <ServiceForm id={selected?.id} modal={formModal} setSelected={setSelected} />
         <ul>
            {sortRecordsByObjectProp(serviceList, "serviceDate", { desc: true }).map((_service, key) => (
               <Service service={_service} key={key} setSelected={setSelected} formModal={formModal} />
            ))}
         </ul>
      </>
   );
};

export default Services;
