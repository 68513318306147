function Badge({ children, className, ...rest }) {
   return (
      <small className={`py-1 px-2 border border-1 rounded-lg ${className}`} {...rest}>
         {children}
      </small>
   );
}

function DefaultBadge({ children }) {
   return <Badge className="text-slate-500 bg-slate-300 border-slate-400">{children}</Badge>;
}
function PrimaryBadge({ children }) {
   return <Badge className="text-primary bg-bgPrimary border-primary">{children}</Badge>;
}
function SecondaryBadge({ children }) {
   return <Badge className="text-secondary bg-bgSecondary border-secondary">{children}</Badge>;
}
function SuccessBadge({ children }) {
   return <Badge className="text-success bg-bgSuccess border-success">{children}</Badge>;
}
function DangerBadge({ children }) {
   return <Badge className="text-danger bg-bgDanger border-danger">{children}</Badge>;
}

export { DangerBadge, DefaultBadge, PrimaryBadge, SecondaryBadge, SuccessBadge };
