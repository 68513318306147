import { AxiosRequestConfig } from "axios";
import { endpoints } from "../../../config/endpoints";
import { axiosPrivateFetch } from "../../../services/fetch-service";
import { ApiResponse } from "../../../types";

const getGroupType = async (groupTypeId: string) => {
   return axiosPrivateFetch.get(`${endpoints.groupTypes}/${groupTypeId}`) as any as ApiResponse;
};

const getGroupTypes = async ({ page, rows, search }) => {
   return axiosPrivateFetch.get(endpoints.groupTypes, { params: { page, rows, search } }) as any as ApiResponse;
};

const submitGroupTypeMaintenance = ({ groupType, editOrCreate }) => {
   const options: AxiosRequestConfig = { data: groupType, method: editOrCreate === "edit" ? "PUT" : "POST" };

   const url = editOrCreate === "edit" ? `${endpoints.groupTypes}/${groupType.id}` : endpoints.groupTypes;

   return axiosPrivateFetch(url, options);
};

const deleteGroupType = async (groupTypeId: string) => {
   const options = {
      method: "DELETE",
      url: `${endpoints.groupTypes}/${groupTypeId}`,
   };

   return axiosPrivateFetch(options);
};

const groupTypeService = {
   getGroupTypes,
   getGroupType,
   deleteGroupType,
   submitGroupTypeMaintenance,
};

export default groupTypeService;
