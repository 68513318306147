import validator from "validator";

const MIN_ADDRESS_CHARACTERS = 3,
  MAX_ADDRESS_CHARACTERS = 100;

export default function validateAddress(address = "", { optional } = { optional: false }): string {
  const error = [];

  if (!optional || address) {
    if (typeof address !== "string") {
      error.push("Invalid address");
      return error.join(" ");
    }
    if (!address) {
      error.push("Address is required");
      return error.join(" ");
    }
    if (!validator.isLength(address, { min: MIN_ADDRESS_CHARACTERS, max: MAX_ADDRESS_CHARACTERS }))
      error.push(
        `Only ${MIN_ADDRESS_CHARACTERS}-${MAX_ADDRESS_CHARACTERS} characters is allowed for address`
      );
    if (!validator.isAlphanumeric(address, "en-GB", { ignore: " -,." }))
      error.push(
        `Only alphabets, numbers, spaces, comma, full stop, and hyphen are allowed for address`
      );
  }

  return error.join(" ");
}
