import { Modal, ModalBody, ModalContent, ModalOverlay, Switch, useToast } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { Form, Formik } from "formik";
import { useState } from "react";
import validator from "validator";
import { Button } from "../../components/ui/button/Button";
import { FieldError, FieldLabel, TextInput } from "../../components/ui/form";
import Workspace from "../../components/workspace";
import SMSService from "../../services/sms-service";
import { validateSMS } from "../../utils/validators/validateSMS";
import { default as groupService } from "../membership/groups/group.service";

const ALLOWED_QUESTION_CHAR_LENGTH = 160 * 2;
const MEMBERSHIP_GROUPS = [
   { name: "Full Members", id: "FULL MEMBERS" },
   { name: "First Timers", id: "FIRST TIMERS" },
   { name: "New Converts", id: "NEW CONVERTS" },
   { name: "Attendees", id: "ATTENDEES" },
];

const SMSForm = ({ modal, refresh = () => {} }) => {
   const [successNotificationText, setSuccessNotificationText] = useState("");
   const [submitError, setSubmitError] = useState();
   const toast = useToast();

   const heading = "Compose new SMS";
   const controller = new AbortController();

   const groupsFetcher = async () => {
      try {
         const fetchResult = await groupService.getAllGroups();
         const withMembershipLevels = MEMBERSHIP_GROUPS.concat(fetchResult.data);

         return withMembershipLevels;
      } catch (error) {
         toast({ status: "error", description: error.message, title: "Fetching groups failed.", isClosable: true });
         throw error;
      }
   };

   const { data: groups, isLoading, error: fetchError } = useQuery({ queryKey: ["groups"], queryFn: groupsFetcher });

   const reset = () => {
      setSuccessNotificationText(null);
      setSubmitError(null);
   };

   const resetOnClose = () => {
      reset();
      modal.onClose();
   };

   if (isLoading) return <Workspace.LoadingModal controller={controller} heading={heading} modal={modal} />;
   if (fetchError) return <Workspace.FetchErrorModal modal={modal} heading={heading} errorMessage={fetchError} callback={reset} />;

   if (successNotificationText)
      return (
         <Workspace.SuccessNotificationModal
            modal={modal}
            message={successNotificationText}
            callback={() => {
               reset();
               refresh();
            }}
         />
      );

   const handleSubmit = async (sms) => {
      setSubmitError(null);
      const smsRecord = Object.assign({}, sms);

      try {
         smsRecord.receivers = smsRecord.receivers ? smsRecord.receivers?.trim()?.split(",") : "";
         smsRecord.receivers = smsRecord.everyone ? ["ALL"] : smsRecord.receivers;
         smsRecord.message = validator.escape(smsRecord.message || "");

         const submitResult = await SMSService.sendSMS(smsRecord);
         setSuccessNotificationText(submitResult.message);
      } catch (error) {
         setSubmitError(error.message);
      }
   };

   return (
      <Modal isOpen={modal.isOpen} onClose={resetOnClose} size="2xl">
         <ModalOverlay />
         <ModalContent className="!rounded-2xl">
            <Workspace.ModalHeader>
               <Workspace.ModalHeaderWithCloseButton>{heading}</Workspace.ModalHeaderWithCloseButton>
            </Workspace.ModalHeader>
            <ModalBody className="grid">
               <div className="py-5 px-4">
                  <div className="flex flex-wrap items-center">
                     <div className="w-full">
                        <Formik initialValues={{ receivers: "", groupReceivers: [] }} validate={validateSMS} onSubmit={handleSubmit}>
                           {({ values, isSubmitting, setFieldError, handleChange, errors, handleBlur }) => (
                              <Form className="grid gap-6">
                                 <div className="flex gap-x-4">
                                    <FieldLabel htmlFor="everyone">Send to everyone:</FieldLabel>
                                    <Switch id="everyone" name="everyone" onChange={handleChange} />
                                 </div>

                                 {Boolean(values.everyone) ? null : (
                                    <>
                                       <TextInput
                                          type="text"
                                          label={
                                             <div className="grid">
                                                Recipient(s)
                                                <span className=" text-xs italic font-thin">Enter phone numbers separated with comma</span>
                                             </div>
                                          }
                                          id="receivers"
                                          name="receivers"
                                       />
                                       <div>
                                          <FieldLabel htmlFor="groupReceivers">Group Receivers</FieldLabel>
                                          <div className="flex flex-wrap gap-2 border border-[#ced4da] rounded overflow-hidden text-[0.8rem] w-full px-4 py-[0.7rem]">
                                             {groups.map((_group) => (
                                                <label
                                                   className="flex items-center px-4 py-2 rounded-md cursor-pointer has-[:checked]:bg-primary has-[:checked]:text-white bg-bgSecondary "
                                                   key={_group.id}>
                                                   <input
                                                      className="me-1"
                                                      type="checkbox"
                                                      id="groupReceivers"
                                                      name="groupReceivers"
                                                      value={_group.id}
                                                      onChange={handleChange}
                                                      onBlur={handleBlur}
                                                   />
                                                   {_group.name}
                                                </label>
                                             ))}
                                          </div>
                                       </div>
                                    </>
                                 )}
                                 <div className="form-group | ">
                                    <div className="flex justify-between items-center">
                                       <FieldLabel htmlFor="message">Message</FieldLabel>
                                       <span className="text-sm">
                                          {values.message?.length || 0} / {ALLOWED_QUESTION_CHAR_LENGTH}
                                       </span>
                                    </div>
                                    <textarea
                                       rows={5}
                                       id="message"
                                       name="message"
                                       className="form-control | border border-[#ced4da] rounded overflow-hidden text-[0.8rem] w-full px-4 py-[0.7rem] resize-none"
                                       value={values.message}
                                       onChange={(e) => {
                                          const shouldAppendNewCharacter = e.target.value.length <= ALLOWED_QUESTION_CHAR_LENGTH;
                                          if (!shouldAppendNewCharacter) {
                                             setFieldError("message", "Maximum length reached");
                                          } else {
                                             handleChange(e);
                                          }
                                       }}
                                    />
                                    {errors.message ? <FieldError className="mt-1">{errors.message}</FieldError> : null}
                                 </div>
                                 {submitError && (
                                    <div className="mb-5">
                                       <Workspace.FormSubmitError>{submitError}</Workspace.FormSubmitError>
                                    </div>
                                 )}
                                 <div className="mb-3 flex justify-end gap-2">
                                    <Button
                                       type="reset"
                                       variant="secondary"
                                       onClick={() => {
                                          controller.abort();
                                          resetOnClose();
                                       }}>
                                       Cancel
                                    </Button>
                                    <Button variant="primary" type="submit" className="btn btn-primary" disabled={isSubmitting}>
                                       Send
                                    </Button>
                                 </div>
                              </Form>
                           )}
                        </Formik>
                     </div>
                  </div>
               </div>
            </ModalBody>
         </ModalContent>
      </Modal>
   );
};

export default SMSForm;
