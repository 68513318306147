import { useToast } from "@chakra-ui/react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import Pagination from "../../../components/Pagination";
import { Loading } from "../../../components/ui/loading";
import { ResourceLoadingError } from "../../../components/ui/resource-loading-error";
import Workspace from "../../../components/workspace";
import { ApiResponse } from "../../../types";
import groupService from "./group.service";
import { Groups } from "./groups";

type Group = {
   name: string;
};

const GroupListContainer = ({ searchGroup: searchQuery = "" }) => {
   const [page, setPage] = useState(1);
   const [rows] = useState(10);
   const [totalPages, setTotalPages] = useState(1);
   const toast = useToast();
   const queryClient = useQueryClient();
   const refreshData = () => queryClient.invalidateQueries({ queryKey: ["groups", page, rows, searchQuery] });

   const loadGroups = async () => {
      try {
         const fetchResult = (await groupService.getGroups({
            page,
            rows,
            search: searchQuery,
         })) as any as ApiResponse;

         setTotalPages(fetchResult.totalPages);
         return fetchResult.data as Group[];
      } catch (error) {
         toast({ description: error.message, status: "error", title: "Groups loading" });
         throw new Error(error.message || error);
      }
   };

   const {
      data: groups,
      error: fetchError,
      isLoading,
      isError,
   } = useQuery({ queryKey: ["groups", page, rows, searchQuery], queryFn: loadGroups });

   if (isLoading) return <Loading />;
   if (isError) return <ResourceLoadingError error={fetchError || ""} />;

   return (
      <Workspace.ListWrapper>
         <Groups groups={groups} refreshData={refreshData} />
         {groups?.length ? <Pagination setPage={setPage} page={page} totalPages={totalPages} /> : null}
      </Workspace.ListWrapper>
   );
};

export default GroupListContainer;
