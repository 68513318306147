const endpointPrefix = process.env.NODE_ENV === "development" ? "/" : "https://api.theharvestword.org/";

export const endpoints = {
   login: endpointPrefix + "login",
   logout: endpointPrefix + "logout",
   refreshToken: endpointPrefix + "refresh-token",
   audios: endpointPrefix + "audios",
   audiosV1: endpointPrefix + "v1/audios",
   branches: endpointPrefix + "branches",
   branchesV1: endpointPrefix + "v1/branches",
   users: endpointPrefix + "users",
   roles: endpointPrefix + "roles",
   sms: endpointPrefix + "v1/sms",
   liveEvents: endpointPrefix + "v1/live-events",
   groups: endpointPrefix + "v1/groups",
   groupTypes: endpointPrefix + "v1/group-types",
   permissions: endpointPrefix + "permissions",
   members: endpointPrefix + "v1/members",
   membersReport: endpointPrefix + "v1/members/report",
   offerings: endpointPrefix + "offerings",
   dashboard: endpointPrefix + "v1/dashboard",
   services: endpointPrefix + "services",
   attendances: endpointPrefix + "attendances",
   liveQuestions: endpointPrefix + "live-questions",
   profiles: endpointPrefix + "profiles",
   editPassword: endpointPrefix + "edit-password",
   recoverPassword: endpointPrefix + "v1/recover-password",
   resetPassword: endpointPrefix.replace(/\/$/, ""),
};

export const productionBaseURL = "https://api.theharvestword.org/";
