import { Menu, MenuItem, MenuList } from "@chakra-ui/react";
import { BiPencil, BiTrashAlt } from "react-icons/bi";
import { RiListSettingsLine } from "react-icons/ri";
import { ComponentRequireRole } from "../../../components/ComponentRequireRole";
import { PaddedIcon } from "../../../components/ui/button/Icon";
import Workspace from "../../../components/workspace";
import { roleMap } from "../../../config/roleMapping";

const { pastoralCareDirectorate } = roleMap;

const Group = ({ group, formModal, deleteGroupDialog, setSelected, manageGroupModal }) => {
   return (
      <Workspace.ItemWrapper
         onClick={() => {
            setSelected(group);
            formModal.onOpen();
         }}>
         <div className="flex justify-between items-center px-4 py-5">
            <input type="checkbox" className="me-2 md:me-4" onClick={(e) => e.stopPropagation()} />
            <p className="mx-2 w-full font-bold overflow-hidden overflow-ellipsis">{`${group.name}`}</p>
            <p className="w-1/2 md:w-1/2 text-sm">{group.description}</p>
            <p className="role__role w-4/12 mx-1">{group.groupType.name}</p>
            <Workspace.ItemActionsWrapper>
               <PaddedIcon variant="primary" aria-label="edit">
                  <BiPencil className="text-lg" />
               </PaddedIcon>

               <Menu>
                  <Workspace.ItemMenuButton />
                  <MenuList className="!py-4">
                     <ComponentRequireRole requiredRoles={[pastoralCareDirectorate]}>
                        <>
                           <MenuItem
                              className="flex gap-2 !py-3"
                              onClick={(e) => {
                                 e.stopPropagation();
                                 setSelected(group);
                                 manageGroupModal.onOpen();
                              }}>
                              <RiListSettingsLine /> Manage members
                           </MenuItem>
                           <MenuItem
                              className="flex gap-2 !py-3 !text-red-600"
                              onClick={(e) => {
                                 setSelected(group);
                                 e.stopPropagation();
                                 deleteGroupDialog.onOpen();
                              }}>
                              <BiTrashAlt /> Delete
                           </MenuItem>
                        </>
                     </ComponentRequireRole>
                  </MenuList>
               </Menu>
            </Workspace.ItemActionsWrapper>
         </div>
      </Workspace.ItemWrapper>
   );
};

export default Group;
