import validator from "validator";

export default function validateName(name = "", type: string): string {
  const error = [];

  const MIN_CHARACTERS = 3,
    MAX_CHARACTERS = 25;

  if (validator.isEmpty(name)) {
    error.push(type + " is required");
  } else {
    if (!validator.isLength(name, { min: MIN_CHARACTERS, max: MAX_CHARACTERS }))
      error.push(
        `Only ${MIN_CHARACTERS} - ${MAX_CHARACTERS} characters are allowed for ${type.toLowerCase()}`
      );

    if (!validator.isAlpha(name, "en-GB", { ignore: " -" }))
      error.push(`Only alphabets, space and hyphen are allowed for ${type.toLowerCase()}`);
    if (name.endsWith("-")) {
      error.push("Name not allowed.");
    }
  }

  return error.join(" ");
}
