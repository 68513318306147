import validator from "validator";

export default function validateEmail(email = "", { optional } = { optional: false }): string {
  const error = [];

  if (!optional || email) {
    if (!validator.isEmail(email))
      error.push("Email address not allowed. Format allowed sample@provider.com");
  }

  return error.join(" ");
}
