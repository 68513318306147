import validator from "validator";

export default function validateEntityDescription(
  description = "",
  { optional, entity }: { optional: boolean; entity: string }
): string {
  const error = [];

  if (!optional || description) {
    if (validator.isEmpty(description)) {
      error.push(entity + " description is required");
    } else {
      if (!validator.isLength(description, { min: 4, max: 70 }))
        error.push("Only 4 - 70 characters allowed for " + entity.toLowerCase() + "description");

      if (!validator.isAlphanumeric(description, "en-GB", { ignore: " -:," }))
        error.push(
          `Only alphanumeric characters are allowed for ${entity.toLowerCase()} description`
        );
    }
  }

  return error.join(" ");
}
