import EmptyList from "../../components/ui/EmptyList";
import { Attendance } from "./attendance";

function Attendances({ attendanceList = [] }) {
   if (!attendanceList.length) return <EmptyList message="Attendance records would be listed here" />;
   return (
      <ul>
         {attendanceList.map((_attendance, key) => (
            <Attendance attendance={_attendance} key={key} />
         ))}
      </ul>
   );
}

export default Attendances;
