import { Modal, ModalBody, ModalContent, ModalOverlay, useToast } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { Form, Formik } from "formik";
import { Button } from "../../../components/ui/button/Button";
import { FieldError, FieldLabel } from "../../../components/ui/form";
import Workspace from "../../../components/workspace";
import { endpoints } from "../../../config/endpoints";
import { axiosPrivateFetch } from "../../../services/fetch-service";
import { validateText } from "../../../utils/validators/validateText";

const MAX_CHARACTER_LENGTH = 160 * 2;

const SendSMSToMember = ({ member, modal }) => {
   const toast = useToast();
   const validateSMS = ({ message }) => {
      const messageValidationResult = validateText(message, {
         optional: false,
         entity: "Message",
         maxCharacterLength: MAX_CHARACTER_LENGTH,
         minCharacterLength: 10,
      });

      return { ...messageValidationResult };
   };

   const handleSubmit = useMutation({
      mutationFn: ({ message, receiver }) => {
         const options = {
            method: "POST",
            data: { receivers: [receiver], message },
            url: endpoints.sms,
         };

         return axiosPrivateFetch(options);
      },
      onSuccess: (data) => {
         toast({ title: "SMS created.", description: data.message, status: "success" });
         modal.onClose();
      },
   });

   return (
      <Modal isOpen={modal.isOpen} onClose={modal.onClose} size="xl">
         <ModalOverlay />
         <ModalContent className="!rounded-2xl overflow-hidden">
            <Workspace.ModalHeader>
               <Workspace.ModalHeaderWithCloseButton>Send SMS</Workspace.ModalHeaderWithCloseButton>
            </Workspace.ModalHeader>

            <ModalBody>
               <div className="py-4">
                  <Formik
                     initialValues={{ receiver: member?.phoneNo, message: "" }}
                     validate={validateSMS}
                     onSubmit={handleSubmit.mutate}
                     enableReinitialize>
                     {({ values, errors, handleChange, setFieldError }) => (
                        <Form className="grid gap-6">
                           <div className="grid mb-4">
                              <FieldLabel htmlFor="receiver">Receiver:</FieldLabel>
                              <input
                                 className="text-[0.8rem] w-full px-3 py-[0.5rem] border border-[#ced4da] rounded overflow-hidden"
                                 type="tel"
                                 id="receiver"
                                 name="receiver"
                                 value={values.receiver}
                                 disabled
                                 required
                              />
                              {errors.receiver ? <FieldError>{errors.receiver}</FieldError> : null}
                           </div>
                           <div className="grid mb-4">
                              <div className="flex justify-between">
                                 <FieldLabel htmlFor="receiver">Message:</FieldLabel>
                                 <p className="text-sm">
                                    {values?.message?.length || 0}/{MAX_CHARACTER_LENGTH}
                                 </p>
                              </div>

                              <textarea
                                 className="text-[0.8rem] w-full px-3 py-[0.5rem] border border-[#ced4da] rounded overflow-hidden resize-none"
                                 id="message"
                                 name="message"
                                 value={values.message}
                                 rows={5}
                                 required
                                 onChange={(e) => {
                                    const shouldAppendNewCharacter = e.target.value.length <= MAX_CHARACTER_LENGTH;
                                    if (!shouldAppendNewCharacter) {
                                       setFieldError("message", "Maximum length reached");
                                    } else {
                                       handleChange(e);
                                    }
                                 }}
                              />
                              {errors.message ? <FieldError className="mt-1">{errors.message}</FieldError> : null}
                           </div>
                           {handleSubmit.error?.message ? (
                              <FieldError className="mt-1">{handleSubmit.error.message}</FieldError>
                           ) : null}

                           <div className="mb-3 flex justify-end gap-2">
                              <Button variant="secondary" type="reset" onClick={modal.onClose}>
                                 Cancel
                              </Button>
                              <Button variant="primary" type="submit" disabled={handleSubmit.isPending}>
                                 {handleSubmit.isPending ? "Sending..." : "Send"}
                              </Button>
                           </div>
                        </Form>
                     )}
                  </Formik>
               </div>
            </ModalBody>
         </ModalContent>
      </Modal>
   );
};

export { SendSMSToMember };
