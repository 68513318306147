import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { Button } from "../components/ui/button/Button";
import Workspace from "../components/workspace";

export default function PageNotFound() {
   const navigate = useNavigate();
   return (
      <div className="min-h-full">
         <Workspace.ComponentWrapper className="h-[95%] my-[1%] grid place-content-center p-10">
            <div className="md:w-6/12 lg:w-8/12 mx-auto mb-5 text-center">
               <p className="font-bold text-8xl text-danger">404</p>
               <p className="text-3xl">Oops! We can&apos;t find the page you&apos;re looking for.</p>
               <p className="text-sm">You are not lost. It appears we didn&apos;t make provision for this page</p>
            </div>
            <div className="my-3 mx-auto">
               <Button
                  variant="primary"
                  onClick={() => {
                     navigate(-1);
                  }}>
                  <BiArrowBack className="me-2" /> Go back
               </Button>
            </div>
         </Workspace.ComponentWrapper>
      </div>
   );
}
