import { SimpleEntity, SimpleEntityValidationError } from "../../../types";
import stripEmptyObjectProp from "../../../utils/stripEmptyObjectProp";
import validateEntityDescription from "../../../utils/validators/validateEntityDescription";
import validateEntityName from "../../../utils/validators/validateEntityName";

type GroupType = SimpleEntity & { groupType: string };

export default function validateGroup({ name, description, groupType }: GroupType): object {
   const errors = {} as SimpleEntityValidationError & { groupType: string };
   errors.name = validateEntityName(name, "Group");
   errors.description = validateEntityDescription(description, { optional: false, entity: "Group" });
   errors.groupType = !Boolean(groupType) ? "Group type is required. Please select group type." : "";

   return stripEmptyObjectProp(errors);
}
