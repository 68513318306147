import validator from "validator";

const MIN_CHARACTER = 4;
const MAX_CHARACTER = 50;

export default function validateEntityName(name = "", entity: string): string {
   const error = [];

   if (validator.isEmpty(name)) {
      error.push(entity + " name is required");
   } else {
      if (!validator.isLength(name, { min: MIN_CHARACTER, max: MAX_CHARACTER }))
         error.push(`Only ${MIN_CHARACTER} - ${MAX_CHARACTER} characters allowed for ${entity.toLowerCase()} name`);

      if (!validator.isAlphanumeric(name, "en-GB", { ignore: " -()_:" }))
         error.push(`Only alphanumeric characters are allowed for ${entity.toLowerCase()} name`);
   }

   return error.join(" ");
}
