import { createContext, useContext, useState } from "react";

const FeatureFlagContext = createContext({});

const FeatureFlagProvider = ({ children }) => {
   const [featureFlag] = useState(process.env.REACT_APP_FEATURE_FLAG || "{}");

   return <FeatureFlagContext.Provider value={JSON.parse(featureFlag)}>{children}</FeatureFlagContext.Provider>;
};

const useFeatureFlag = () => {
   return useContext(FeatureFlagContext);
};

export { FeatureFlagProvider, useFeatureFlag };
