function Index({ index }) {
  return (
    <div
      className="index__item bg-white flex justify-between items-center py-3 px-4 rounded-2xl"
      data-testid="anIndex">
      <div>
        <span className="index__number block text-2xl font-semibold">{index.value}</span>
        <span className="text-[0.8rem]">{index.name}</span>
      </div>
      <div
        className="index__icon text-white w-[22%] aspect-square text-[2rem] md:text-2xl font-semibold flex items-center justify-center rounded-xl"
        style={{ backgroundColor: index.color }}>
        <i className={index.icon}></i>
      </div>
    </div>
  );
}

export default Index;
