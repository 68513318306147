import {
   MenuButton,
   Modal,
   ModalBody,
   ModalCloseButton,
   ModalContent,
   ModalFooter,
   ModalHeader,
   ModalOverlay,
} from "@chakra-ui/react";
import { BiCheckCircle, BiDotsVertical } from "react-icons/bi";
import { BsArrowLeftShort } from "react-icons/bs";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { sanitize } from "../../utils/stripEmptyObjectProp";
import { Button } from "../ui/button/Button";
import { PaddedIcon } from "../ui/button/Icon";
import { Loading } from "../ui/loading";
import { ResourceLoadingError } from "../ui/resource-loading-error";

const Workspace = ({ children }) => {
   return <div className="workspace |">{children}</div>;
};

const WorkspaceTopBar = ({ heading }) => {
   function displaySidebar() {
      document.querySelector(".sidebar__content")?.classList.add("sidebar-active");
      document.querySelector(".overlay")?.classList.add("sidebar-active");
   }

   return (
      <div className="top-bar flex justify-between items-center my-8 mb-6">
         <h1 className="mb-0 text-2xl">{heading}</h1>
         <div className="notification-area | bg-white rounded-lg flex text-lg ms-3 overflow-hidden">
            <i className="bi bi-bell px-3 hover:bg-[--clr-secondary] hover:text-white flex py-3" />

            <Link to="/settings" title="Settings">
               <i
                  className="bi bi-gear px-3 hover:bg-[--clr-secondary] hover:text-white flex py-3"
                  aria-label="Settings"
                  data-testid="settings"></i>
            </Link>
            <i
               className="bi bi-list px-3 lg:hidden cursor-pointer hover:bg-[--clr-secondary] hover:text-white flex py-3"
               onClick={displaySidebar}></i>
         </div>
      </div>
   );
};

const WorkspaceHeader = ({ children }) => {
   return <header className="ws-header | font-medium text-[0.9rem] border-b-[1px] border-b-[#ddd]">{children}</header>;
};

const WorkspaceHeaderWrapper = ({ children, ...rest }) => {
   return (
      <div className="flex flex-col md:flex-row gap-y-4 items-center justify-between p-4 md:p-6" {...rest}>
         {children}
      </div>
   );
};

const WorkspaceSubHeading = ({ children }) => {
   return <h2 className="ws-sub-heading | text-center md:text-left text-xl mb-0">{children}</h2>;
};

const WorkspaceHeaderSearch = ({ children }) => {
   return <div className="ws-header-search | flex w-full md:w-2/5">{children}</div>;
};

const WorkspaceSearchForm = ({ name = "search", placeholder = "Search", onSubmit }) => {
   return (
      <form
         className="w-full"
         onSubmit={(e) => {
            e.preventDefault();
            onSubmit(sanitize(e.target.search.value));
         }}>
         <input
            className="form-control | text-[0.8rem] w-full px-4 py-[0.8rem] border border-[#ced4da] rounded-lg overflow-hidden"
            title="Search"
            spellCheck="false"
            aria-label="Search"
            autoCorrect="false"
            placeholder={placeholder}
            type="search"
            name={name}
         />
      </form>
   );
};

const WorkspaceHeaderLinks = ({ children }) => {
   return (
      <div className="ws-header-link-wrapper | p-4 px-8">
         <div className="p-3 py-4 rounded-lg inline-block bg-gray-100">{children}</div>
      </div>
   );
};

const WorkspaceLinkTab = ({ to, name }) => {
   return (
      <NavLink
         className={({ isActive }) => {
            return `py-2 px-5 lg:px-5 rounded-md ${isActive ? "text-primary bg-white" : ""}`;
         }}
         to={to}>
         {name}
      </NavLink>
   );
};

const WorkspaceActions = ({ children }) => {
   return <div className="flex md:justify-end gap-2 my-4">{children}</div>;
};

const WorkspaceBackButton = () => {
   const navigate = useNavigate();

   return (
      <Button variant="primary" onClick={() => navigate("")}>
         <span className="inline-block text-xl me-2">
            <BsArrowLeftShort />
         </span>
         Back
      </Button>
   );
};

function WorkspaceNewButton({ children }) {
   const navigate = useNavigate();

   return (
      <Button variant="primary" onClick={() => navigate("new")}>
         {children}
      </Button>
   );
}
function WorkspaceComponentWrapper({ children, className = "" }) {
   return (
      <div
         className={`workspace-wrapper | min-h-[5rem] bg-white border-secondary-local rounded-2xl overflow-hidden mb-8 pb-6 ${className}`}>
         {children}
      </div>
   );
}

const CustomModalHeader = ({ children }) => {
   return <ModalHeader className="border-b-[1px] border-b-[#ddd]">{children}</ModalHeader>;
};

const ModalHeaderWithCloseButton = ({ children }) => {
   return (
      <div className="flex justify-between">
         {children}
         <ModalCloseButton className="!relative !top-0 !right-0" />
      </div>
   );
};

const LoadingModal = ({ modal, controller, heading = "Loading" }) => {
   const { isOpen, onClose } = modal;
   return (
      <Modal
         isOpen={isOpen}
         size="2xl"
         onClose={() => {
            controller && controller.abort();
            onClose();
         }}>
         <ModalOverlay />
         <ModalContent className="!rounded-2xl">
            <Workspace.ModalHeader>
               <Workspace.ModalHeaderWithCloseButton>{heading}</Workspace.ModalHeaderWithCloseButton>
            </Workspace.ModalHeader>
            <ModalBody>
               <Loading />
            </ModalBody>
         </ModalContent>
      </Modal>
   );
};

const FetchErrorModal = ({ modal, message, heading = "Error", callback = () => {} }) => {
   const { isOpen, onClose } = modal;
   return (
      <Modal isOpen={isOpen} onClose={onClose} size="2xl">
         <ModalOverlay />
         <ModalContent className="!rounded-2xl">
            <Workspace.ModalHeader>
               <Workspace.ModalHeaderWithCloseButton>{heading}</Workspace.ModalHeaderWithCloseButton>
            </Workspace.ModalHeader>
            <ModalBody>
               <ResourceLoadingError
                  errorMessage={message}
                  onOk={() => {
                     callback();
                     onClose();
                  }}
               />
            </ModalBody>
         </ModalContent>
      </Modal>
   );
};

const FormSubmitError = ({ children }) => {
   return <div className="text-center text-sm py-2 px-4 rounded border bg-bgDanger text-red-700 border-red-400">{children}</div>;
};

const ListWrapper = ({ children }) => {
   return <div className="grid gap-6 mb-5">{children}</div>;
};

const SuccessNotificationModal = ({ modal, message, callback = () => {} }) => {
   const { isOpen, onClose } = modal;
   return (
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
         <ModalOverlay />
         <ModalContent className="!rounded-2xl">
            <ModalBody className="mt-16 w-3/4 mx-auto">
               <div className="success-modal | flex flex-col gap-3 text-center">
                  <BiCheckCircle className="text-9xl text-success mx-auto" />
                  <div className="flex flex-col gap-2 mb-4">
                     <p className="font-semibold text-3xl">Success</p>
                     <p className=" text-gray-500">{message}</p>
                  </div>
               </div>
            </ModalBody>
            <ModalFooter>
               <div className="grid w-full md:w-11/12 mx-auto my-6">
                  <Button
                     variant="primary"
                     className="btn btn-primary btn-lg !py-4"
                     onClick={() => {
                        callback();
                        onClose();
                     }}>
                     OK
                  </Button>
               </div>
            </ModalFooter>
         </ModalContent>
      </Modal>
   );
};

const ItemMenuButton = () => {
   return (
      <MenuButton onClick={(e) => e.stopPropagation()}>
         <PaddedIcon variant="secondary">
            <BiDotsVertical className="text-lg" />
         </PaddedIcon>
      </MenuButton>
   );
};

const ItemActionsWrapper = ({ children }) => {
   return <div className="workspace__actions | flex gap-2 justify-end flex-wrap w-1/12 md:w-3/12">{children}</div>;
};

const ItemWrapper = ({ children, ...rest }) => {
   return (
      <li
         className="workspace__list-item | md:px-2 border-b-[1px] border-b-[#ddd] text-[0.9rem] cursor-pointer hover:bg-[#dfefff] focus:bg-[#dfefff] active:bg-[#acd] last:mb-8"
         {...rest}>
         {children}
      </li>
   );
};

Workspace.WorkspaceHeader = WorkspaceHeader;
Workspace.WorkspaceHeaderSearch = WorkspaceHeaderSearch;
Workspace.SearchForm = WorkspaceSearchForm;
Workspace.WorkspaceHeaderWrapper = WorkspaceHeaderWrapper;
Workspace.WorkspaceSubHeading = WorkspaceSubHeading;
Workspace.WorkspaceActions = WorkspaceActions;
Workspace.WorkspaceHeaderLinks = WorkspaceHeaderLinks;
Workspace.WorkspaceLinkTab = WorkspaceLinkTab;
Workspace.TopBar = WorkspaceTopBar;
Workspace.BackButton = WorkspaceBackButton;
Workspace.NewButton = WorkspaceNewButton;
Workspace.ComponentWrapper = WorkspaceComponentWrapper;
Workspace.ModalHeader = CustomModalHeader;
Workspace.ModalHeaderWithCloseButton = ModalHeaderWithCloseButton;
Workspace.LoadingModal = LoadingModal;
Workspace.FetchErrorModal = FetchErrorModal;
Workspace.FormSubmitError = FormSubmitError;
Workspace.SuccessNotificationModal = SuccessNotificationModal;
Workspace.ListWrapper = ListWrapper;
Workspace.ItemWrapper = ItemWrapper;
Workspace.ItemMenuButton = ItemMenuButton;
Workspace.ItemActionsWrapper = ItemActionsWrapper;

export default Workspace;
