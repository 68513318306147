import { useField } from "formik";

const CheckBox = ({ label, ...rest }) => {
   const [field, meta] = useField(rest);
   return (
      <div className="mb-4 flex items-center">
         <input {...field} {...rest} />
         <label htmlFor={rest.id || rest.name} className="ps-2">
            {label}
         </label>
         {meta.touched && meta.error ? <small className="form-error mt-1">{meta.error}</small> : null}
      </div>
   );
};

export { CheckBox };
