import { useDisclosure } from "@chakra-ui/react";
import { useState } from "react";
import { BiPlus } from "react-icons/bi";
import { Button } from "../../../components/ui/button/Button";
import Workspace from "../../../components/workspace";
import { PermissionForm } from "./PermissionForm";
import PermissionsListContainer from "./PermissionsListContainer";

const PermissionManagementPage = () => {
   const [searchQuery, setSearchQuery] = useState("");
   const formModal = useDisclosure();

   return (
      <>
         {formModal.isOpen ? <PermissionForm modal={formModal} /> : null}
         <Workspace.TopBar heading="Permission Management" />
         {process.env.NODE_ENV !== "production" ? (
            <Workspace.WorkspaceActions>
               <Button variant="primary" onClick={formModal.onOpen}>
                  <span className="inline-flex items-center gap-2">
                     <BiPlus />
                     Add new permission
                  </span>
               </Button>
            </Workspace.WorkspaceActions>
         ) : null}
         <Workspace.ComponentWrapper>
            <Workspace.WorkspaceHeader>
               <Workspace.WorkspaceHeaderWrapper>
                  <Workspace.WorkspaceSubHeading>Permissions</Workspace.WorkspaceSubHeading>
                  <Workspace.WorkspaceHeaderSearch>
                     <Workspace.SearchForm onSubmit={setSearchQuery} />
                  </Workspace.WorkspaceHeaderSearch>
               </Workspace.WorkspaceHeaderWrapper>
            </Workspace.WorkspaceHeader>
            <PermissionsListContainer searchQuery={searchQuery} />
         </Workspace.ComponentWrapper>
      </>
   );
};

export default PermissionManagementPage;
