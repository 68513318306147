import {
   Modal,
   ModalBody,
   ModalContent,
   ModalFooter,
   ModalHeader,
   ModalOverlay,
   useDisclosure,
} from "@chakra-ui/react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import EmptyList from "../../components/ui/EmptyList";
import { SecondaryBadge, SuccessBadge } from "../../components/ui/badge";
import { Button } from "../../components/ui/button/Button";
import Workspace from "../../components/workspace";
import { sortRecordsByObjectProp } from "../../utils/stripEmptyObjectProp";

const LiveEventsList = ({ liveEvents = [] }) => {
   const [selectedEvent, setSelectedEvent] = useState({});
   const eventStateCheckModal = useDisclosure();
   const navigate = useNavigate();

   if (!liveEvents.length) return <EmptyList message="Live events would be listed here" />;

   return (
      <>
         <EventStatusModal modal={eventStateCheckModal} eventAlias={selectedEvent?.alias} />
         <ul>
            {sortRecordsByObjectProp(liveEvents, "status")
               .sort((a, b) => a.createdAt > b.createdAt)
               .map((_event, key) => (
                  <Event
                     event={_event}
                     key={key}
                     modal={eventStateCheckModal}
                     onClick={() => {
                        if (_event.status === "INACTIVE") {
                           setSelectedEvent(_event);
                           eventStateCheckModal.onOpen();
                        } else {
                           navigate(`./${_event.alias}`);
                        }
                     }}
                  />
               ))}
         </ul>
      </>
   );
};

const EventStatusModal = ({ modal, eventAlias }) => {
   const { isOpen, onClose } = modal;
   const navigate = useNavigate();

   return (
      <Modal isOpen={isOpen} onClose={onClose}>
         <ModalOverlay />
         <ModalContent className="rounded-2xl p-4 mx-4">
            <ModalHeader>Info</ModalHeader>
            <ModalBody className="text-center my-4">
               <p>This event is currently inactive and not receiving new questions. Click OK to continue.</p>
            </ModalBody>

            <ModalFooter>
               <div className="flex gap-2 justify-center">
                  <Button
                     variant="secondary"
                     onClick={() => {
                        onClose();
                     }}>
                     Cancel
                  </Button>
                  <Button
                     variant="primary"
                     onClick={() => {
                        navigate(`./${eventAlias}`);
                     }}>
                     OK
                  </Button>
               </div>
            </ModalFooter>
         </ModalContent>
      </Modal>
   );
};

const Event = ({ event, ...rest }) => {
   const statusBadge =
      event.status === "ACTIVE" ? (
         <SuccessBadge>{event.status}</SuccessBadge>
      ) : (
         <SecondaryBadge>{event.status}</SecondaryBadge>
      );

   return (
      <Workspace.ItemWrapper {...rest}>
         <div className="flex justify-between items-center px-4 py-5">
            <div className="mx-2 w-full overflow-hidden overflow-ellipsis">
               <p className="font-bold">{`${event.alias}`}</p>
               <p className="mb-2">{event.description}</p>
            </div>

            <div className="col-3 col-md-2 col-lg-2 text-center" title="Read status">
               {statusBadge}
            </div>
         </div>
      </Workspace.ItemWrapper>
   );
};

export { Event, LiveEventsList };
