import validator from "validator";

export default function validateRole(id = ""): string {
  const error = [];

  if (!id) {
    error.push("Role is required");
  } else if (!validator.isUUID(id)) {
    error.push("Invalid role");
  }

  return error.join(" ");
}
