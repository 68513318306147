import {
   AlertDialog,
   AlertDialogBody,
   AlertDialogContent,
   AlertDialogFooter,
   AlertDialogHeader,
   AlertDialogOverlay,
   useToast,
} from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { useRef } from "react";
import { Button } from "../../../components/ui/button/Button";
import Workspace from "../../../components/workspace";
import { endpoints } from "../../../config/endpoints";
import { axiosPrivateFetch } from "../../../services/fetch-service";

const DeleteMemberDialog = ({ memberId, modal, setMembers }) => {
   const toast = useToast();
   const cancelRef = useRef(null);

   const handleDelete = useMutation({
      mutationFn: (memberId) => {
         const options = {
            method: "DELETE",
            data: { memberId },
            url: `${endpoints.members}/${memberId}`,
         };

         return axiosPrivateFetch(options);
      },
      onSuccess: (data) => {
         toast({ status: "success", title: "Deleted.", description: data.message });
         setMembers((prev) => prev.filter((_member) => _member.id !== memberId));
         modal.onClose();
      },
      onError: (data) => {
         toast({ status: "error", title: "Failed.", description: data.message });
      },
   });

   return (
      <AlertDialog isOpen={modal.isOpen} onClose={modal.onClose} size="xl" leastDestructiveRef={cancelRef}>
         <AlertDialogOverlay />
         <AlertDialogContent className="!rounded-2xl overflow-hidden">
            <AlertDialogHeader className="!p-0">
               <Workspace.ModalHeader className="px-6 py-4 text-xl">
                  <h2>Delete Member</h2>
               </Workspace.ModalHeader>
            </AlertDialogHeader>
            <AlertDialogBody>
               <div className="py-4">
                  Are you sure you want to delete this member record? This action is not reversible.
               </div>
            </AlertDialogBody>
            <AlertDialogFooter>
               <div className="mb-3 flex justify-end gap-2">
                  <Button variant="secondary" ref={cancelRef} onClick={modal.onClose}>
                     Cancel
                  </Button>
                  <Button
                     variant="danger"
                     type="submit"
                     disabled={handleDelete.isPending}
                     onClick={() => handleDelete.mutate(memberId)}>
                     {handleDelete.isPending ? "Please wait..." : "Delete"}
                  </Button>
               </div>
            </AlertDialogFooter>
         </AlertDialogContent>
      </AlertDialog>
   );
};

export { DeleteMemberDialog };
