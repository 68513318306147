import {
   AlertDialog,
   AlertDialogBody,
   AlertDialogContent,
   AlertDialogFooter,
   AlertDialogOverlay,
   useToast,
} from "@chakra-ui/react";
import { BiUserCheck, BiUserX } from "react-icons/bi";
import { LiaUserLockSolid } from "react-icons/lia";
import { Button } from "../../../components/ui/button/Button";
import userService from "../../../services/user-service";

const modalDetails = {
   E: {
      icon: <BiUserCheck />,
      message: "Are you sure you want to enable this profile?",
      buttonText: "Yes, enable profile",
   },
   D: {
      icon: <BiUserX />,
      message: "Are you sure you want to disable this profile?",
      buttonText: "Yes, disable profile",
   },
   L: {
      icon: <LiaUserLockSolid />,
      message: "Are you sure you want to lock this profile?",
      buttonText: "Yes, lock profile",
   },
   U: {
      icon: <BiUserCheck />,
      message: "Are you sure you want to unlock this profile?",
      buttonText: "Yes, unlock profile",
   },
};

const UserStatusAlert = ({ user, setUsers, modal, newStatus }) => {
   const toast = useToast();
   const { isOpen, onClose } = modal;

   const modifiedStatus = ["E", "U"].includes(newStatus) ? "E" : newStatus;

   const changeStatus = async () => {
      try {
         const result = await userService.changeUserStatusFlag({
            newStatus: modifiedStatus,
            userID: user.id,
         });

         setUsers((prev) => prev.map((_user) => (_user.id === user.id ? { ..._user, status: modifiedStatus } : _user)));
         onClose();
         toast({ status: "success", description: result.message });
      } catch (error) {
         toast({ status: "error", description: error.message });
      }
   };

   return (
      <AlertDialog isOpen={isOpen} onClose={onClose}>
         <AlertDialogOverlay />
         <AlertDialogContent className="rounded-2xl p-4 mx-4">
            <AlertDialogBody className="text-center my-8">
               <span className={`text-5xl inline-block ${modifiedStatus === "E" ? "text-green-500" : "text-red-500"}`}>
                  {modalDetails[newStatus]?.icon}
               </span>
               <p>{modalDetails[newStatus]?.message}</p>
            </AlertDialogBody>

            <AlertDialogFooter>
               <div className="flex gap-2 justify-center">
                  <Button variant="outline-secondary" onClick={onClose}>
                     No, cancel
                  </Button>
                  <Button variant={`${modifiedStatus === "E" ? "primary" : "danger"}`} onClick={changeStatus}>
                     {modalDetails[newStatus]?.buttonText}
                  </Button>
               </div>
            </AlertDialogFooter>
         </AlertDialogContent>
      </AlertDialog>
   );
};

export { UserStatusAlert };
