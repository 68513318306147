import validator from "validator";

export default function validateAmount(amount = "") {
  const error = [];
  if (!amount) {
    error.push("Amount is required");
  } else {
    if (!validator.isDecimal(String(amount))) error.push("Amount is not allowed");
  }

  return error.join(" ");
}
