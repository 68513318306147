import { useState } from "react";
import { Link } from "react-router-dom";
import { Button } from "../components/ui/button/Button";
import { FieldLabel } from "../components/ui/form";
import { AuthFormCard } from "../components/ui/form/auth-form-card";
import { FormSubmitError } from "../components/ui/form/submit-error";
import { endpoints } from "../config/endpoints";

const ForgetPasswordPage = () => {
   const [email, setEmail] = useState("");
   const [successMessage, setSuccessMessage] = useState("");
   const [isSubmitting, setIsSubmitting] = useState(false);
   const [submitError, setSubmitError] = useState("");

   const handleForgetPasswordSubmit = async (event) => {
      event.preventDefault();
      setIsSubmitting(true);
      setSubmitError("");
      const options = {
         method: "POST",
         body: JSON.stringify({ email }),
         headers: {
            "Content-Type": "application/json",
         },
      };

      try {
         const response = await fetch(endpoints.recoverPassword, options);
         const responseJson = await response.json();
         if (!responseJson.success) throw Error(responseJson.message);
         setSuccessMessage(responseJson.message);
      } catch (error) {
         setSubmitError(error.message);
         setIsSubmitting(false);
      }
   };

   let currentContent;

   if (successMessage) {
      currentContent = (
         <div className="my-10">
            <p className="text-center text-secondary mb-3">{successMessage}</p>
         </div>
      );
   } else {
      currentContent = (
         <form className="login-form" onSubmit={handleForgetPasswordSubmit}>
            {submitError && <FormSubmitError>{submitError}</FormSubmitError>}
            <div className="mb-3">
               <FieldLabel htmlFor="email">Email Address</FieldLabel>
               <input
                  id="email"
                  className="text-[0.8rem] w-full px-3 py-4 border border-[#ced4da] rounded overflow-hidden"
                  name="email"
                  value={email}
                  placeholder="someone@example.com"
                  onChange={(e) => setEmail(e.target.value)}
                  required
               />
            </div>

            <div className="grid">
               <Button variant="primary" type="submit" disabled={isSubmitting}>
                  {isSubmitting ? "Please wait" : "Send reset link to email"}
               </Button>
            </div>
         </form>
      );
   }

   return (
      <div
         className="login flex justify-center items-center"
         style={{
            width: "100%",
            backgroundImage: `linear-gradient(to bottom, rgba(2, 149, 216, 0.9), rgba(2, 149, 216, 0.9)), url('${process.env.PUBLIC_URL}/login_bg.jpg')`,
         }}>
         <AuthFormCard>
            <div className="absolute w-full -top-12 left-0">
               <div className="p-1 w-24 aspect-square mx-auto rounded-full border border-[#f8f8f8] bg-white">
                  <img src={`${process.env.PUBLIC_URL}/hwa_logo.png`} alt="Logo" />
               </div>
            </div>
            <header className="my-10">
               <h1 className="text-center text-2xl font-bold">Recover password</h1>
               {!successMessage && (
                  <h2 className="text-center text-sm small text-secondary">
                     Enter the email associated with your account and we&#39;ll send you a password reset link.
                  </h2>
               )}
            </header>
            {currentContent}
            <div className="mt-4 text-center">
               <Link to="/login">
                  <small className="fw-600">Back to login page</small>
               </Link>
            </div>
         </AuthFormCard>
      </div>
   );
};

export default ForgetPasswordPage;
