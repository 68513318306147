import { Modal, ModalBody, ModalContent, ModalOverlay, useToast } from "@chakra-ui/react";
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { Button } from "../../../components/ui/button/Button";
import { TextInput } from "../../../components/ui/form";
import { SelectInput } from "../../../components/ui/form/SelectInput";
import Workspace from "../../../components/workspace";
import validateGroup from "./group-validator";
import groupService from "./group.service";

const defaultGroup = {
   name: "",
   description: "",
   groupType: { id: "" },
};

const GroupForm = ({ modal, id, setSelected }) => {
   const [group, setGroup] = useState(defaultGroup);
   const [isLoading, setIsLoading] = useState(id);
   const [successNotificationText, setSuccessNotificationText] = useState("");
   const [fetchError, setFetchError] = useState("");
   const [submitError, setSubmitError] = useState();
   const [groupTypes, setGroupTypes] = useState([]);
   const toast = useToast();

   const heading = id ? "Edit Group" : "Add New Group";
   const controller = new AbortController();
   const signal = controller.signal;

   useEffect(() => {
      async function loadGroupTypes() {
         try {
            const fetchResult = await groupService.getGroupTypes();
            setGroupTypes(fetchResult.data);
         } catch (error) {
            toast({
               status: "error",
               description: "Error loading group types",
               isClosable: true,
               title: "Group Types",
            });
         }
      }
      loadGroupTypes();

      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   useEffect(() => {
      const loadGroupDetails = async () => {
         try {
            const fetchResult = await groupService.getGroup(id);
            const groupDetails = Object.assign({}, fetchResult.data, { groupType: fetchResult.data.groupType.id });
            setGroup(groupDetails);
         } catch (error) {
            setFetchError(error.message);
         } finally {
            setIsLoading(false);
         }
      };
      id && loadGroupDetails();
   }, [id]);

   const handleSubmit = async (group) => {
      setSubmitError(null);

      try {
         const submitResult = await groupService.submitGroupMaintenance({
            group,
            editOrCreate: id ? "edit" : "create",
         });
         setSuccessNotificationText(submitResult.message);
      } catch (error) {
         setSubmitError(error.message);
      }
   };

   const reset = () => {
      setSuccessNotificationText(null);
      setGroup(defaultGroup);
      setSubmitError(null);
      setSelected && setSelected({});
   };

   if (isLoading) return <Workspace.LoadingModal controller={controller} heading={heading} modal={modal} />;
   if (fetchError)
      return <Workspace.FetchErrorModal modal={modal} heading={heading} message={fetchError} callback={reset} />;
   if (successNotificationText)
      return <Workspace.SuccessNotificationModal modal={modal} message={successNotificationText} callback={reset} />;

   const resetOnClose = () => {
      reset();
      modal.onClose();
   };

   return (
      <Modal isOpen={modal.isOpen} onClose={resetOnClose} size="2xl">
         <ModalOverlay />
         <ModalContent className="!rounded-2xl">
            <Workspace.ModalHeader>
               <Workspace.ModalHeaderWithCloseButton>{heading}</Workspace.ModalHeaderWithCloseButton>
            </Workspace.ModalHeader>
            <ModalBody className="grid">
               <div className="py-5 px-4">
                  <div className="flex flex-wrap items-center">
                     <div className="w-full">
                        <Formik
                           initialValues={group}
                           validate={validateGroup}
                           onSubmit={handleSubmit}
                           enableReinitialize>
                           {({ isSubmitting }) => (
                              <Form className="grid gap-6">
                                 <SelectInput label="Group Type" id="groupType" name="groupType" required>
                                    <option value="">Select group type</option>
                                    {groupTypes.map((_groupType) => (
                                       <option key={_groupType.id} value={_groupType.id}>
                                          {_groupType.name}
                                       </option>
                                    ))}
                                 </SelectInput>

                                 <TextInput
                                    type="text"
                                    label="Group Name"
                                    id="name"
                                    name="name"
                                    placeholder="Enter group name here"
                                    required
                                 />
                                 <TextInput
                                    type="text"
                                    label="Description"
                                    id="description"
                                    name="description"
                                    placeholder="Enter group description here"
                                    required
                                 />

                                 {submitError && (
                                    <div className="mb-5">
                                       <Workspace.FormSubmitError>{submitError}</Workspace.FormSubmitError>
                                    </div>
                                 )}

                                 <div className="mb-3 flex justify-end gap-2">
                                    <Button
                                       type="reset"
                                       variant="secondary"
                                       onClick={() => {
                                          controller.abort();
                                          resetOnClose();
                                       }}>
                                       Cancel
                                    </Button>
                                    <Button variant="primary" type="submit" disabled={isSubmitting}>
                                       {!Boolean(id) ? "Create group" : "Edit group"}
                                    </Button>
                                 </div>
                              </Form>
                           )}
                        </Formik>
                     </div>
                  </div>
               </div>
            </ModalBody>
         </ModalContent>
      </Modal>
   );
};

export default GroupForm;
