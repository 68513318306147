import { useToast } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import React, { useContext, useState } from "react";
import branchService from "../services/branch-service";
import { BranchContext } from "../types";

const BranchListContext = React.createContext(undefined);
BranchListContext.displayName = "BranchListContext";

const BranchListStateProvider = ({ children }: { children: React.ReactNode }) => {
   const [requestBranch, setRequestBranch] = useState<string | null>(() => {
      const cookieBranch = document.cookie
         .split("; ")
         .find((row) => row.startsWith("requestBranch="))
         ?.split("=")[1];
      return cookieBranch || null;
   });

   const toast = useToast();

   const branchFetcher = async () => {
      try {
         const fetchResult = await branchService.getBranches({ findAll: true });
         return fetchResult.data;
      } catch (error) {
         toast({ status: "error", description: error.message, title: "Fetching branches.", isClosable: true });
         throw error;
      }
   };

   const { data: branches } = useQuery({ queryKey: ["branches"], queryFn: branchFetcher, refetchOnWindowFocus: false });

   const setRequestBranchAsCookie = (branchCode: string) => {
      setRequestBranch(branchCode);
      const newRequestBranchCookieString =
         process.env.NODE_ENV === "production"
            ? `requestBranch=${branchCode}; domain=.theharvestword.org`
            : `requestBranch=${branchCode}; domain=localhost`;

      document.cookie = newRequestBranchCookieString;
   };

   return (
      <BranchListContext.Provider
         value={{
            branches: branches || [],
            requestBranch,
            setRequestBranchAsCookie,
         }}>
         {children}
      </BranchListContext.Provider>
   );
};

const useBranches = () => {
   return useContext(BranchListContext) as BranchContext;
};

export { BranchListContext, BranchListStateProvider, useBranches };
