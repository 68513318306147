type PaddedIconV1Prop = React.ComponentProps<"button"> & {
   variant?: "primary" | "secondary" | "danger";
   as?: "button";
};

const PaddedIcon = ({ children, variant, disabled, className, as, ...rest }: PaddedIconV1Prop) => {
   const baseClassName =
      "rounded-full w-10 aspect-square border-0 cursor-pointer disabled:cursor-auto flex justify-center items-center";
   let variantClassNames: string = "";
   switch (variant) {
      case "primary":
         variantClassNames = "bg-bgPrimary text-primary hover:bg-bgPrimary/90";
         break;
      case "secondary":
         variantClassNames = "bg-bgSecondary text-secondary";
         break;
      case "danger":
         variantClassNames = "bg-red-200 text-danger hover:bg-red-300";
         break;

      default:
         break;
   }

   if (as === "button") {
      return (
         <button
            className={`${baseClassName} ${variantClassNames} ${className}`}
            tabIndex={0}
            disabled={disabled}
            {...rest}>
            {children}
         </button>
      );
   }

   return (
      <span className="border-0" tabIndex={0} {...rest}>
         <i className={`${baseClassName} ${variantClassNames} ${className}`}>{children}</i>
      </span>
   );
};

export { PaddedIcon };
