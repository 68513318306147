import {
   AlertDialog,
   AlertDialogBody,
   AlertDialogContent,
   AlertDialogFooter,
   AlertDialogHeader,
   AlertDialogOverlay,
   UseModalProps,
} from "@chakra-ui/react";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import authService from "../../services/auth-service";
import "../../styles/sidebar.css";
import { Button } from "../ui/button/Button";

type DialogProps = { modal: UseModalProps };

const LogoutDialog = ({ modal }: DialogProps) => {
   const navigate = useNavigate();
   const cancelButton = useRef(null);

   async function handleLogout() {
      navigate("/login");
      try {
         await authService.logout();
      } catch (error) {
         console.log(error);
      }
   }
   return (
      <AlertDialog isOpen={modal.isOpen} leastDestructiveRef={cancelButton} onClose={modal.onClose}>
         <AlertDialogOverlay>
            <AlertDialogContent>
               <AlertDialogHeader>
                  <span className="font-bold">Confirm logout</span>
               </AlertDialogHeader>
               <AlertDialogBody className="text-center py-8">Are you sure you want to log out?</AlertDialogBody>
               <AlertDialogFooter className="grid gap-4">
                  <Button variant="outline-secondary" ref={cancelButton} onClick={modal.onClose}>
                     Cancel
                  </Button>
                  <Button
                     variant="danger"
                     onClick={() => {
                        modal.onClose();
                        handleLogout();
                     }}>
                     Yes, Log out
                  </Button>
               </AlertDialogFooter>
            </AlertDialogContent>
         </AlertDialogOverlay>
      </AlertDialog>
   );
};

export { LogoutDialog };
