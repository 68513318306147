import { AxiosRequestConfig } from "axios";
import { endpoints } from "../config/endpoints";
import { Branch } from "../types";
import { axiosPrivateFetch } from "./fetch-service";

type getBranchesProp = { findAll?: boolean; page?: number; rows?: number; search?: string };

function getBranches({ findAll, page, rows, search }: getBranchesProp) {
   const url = findAll ? `${endpoints.branchesV1}/all` : `${endpoints.branchesV1}`;
   const options = !findAll ? { params: { page, rows, search } } : {};
   return axiosPrivateFetch.get(url, options);
}

function getBranch(branchCode: string) {
   return axiosPrivateFetch.get(`${endpoints.branches}/${branchCode}`);
}

function submitBranchMaintenance({ branch, editOrCreate }: { branch: Branch; editOrCreate: "edit" | "create" }) {
   const options: AxiosRequestConfig =
      editOrCreate === "edit"
         ? {
              method: "PUT",
           }
         : { method: "POST" };
   options.data = branch;

   const url = editOrCreate === "edit" ? `${endpoints.branches}/${branch.branchCode}` : endpoints.branches;

   return axiosPrivateFetch(url, options);
}

const branchService = { getBranches, getBranch, submitBranchMaintenance };

export default branchService;
