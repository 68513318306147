import { useDisclosure } from "@chakra-ui/react";
import { useState } from "react";
import EmptyList from "../../../components/ui/EmptyList";
import User from "./User";
import UserForm from "./UserForm";
import { UserStatusAlert } from "./user-status-alert";

const Users = ({ usersRecordList = [], setUsers }) => {
   const [selected, setSelected] = useState({});
   const [newStatus, setNewStatus] = useState(null);
   const formModal = useDisclosure();
   const statusModal = useDisclosure();

   if (!usersRecordList.length) return <EmptyList message="Users would be listed here" />;
   return (
      <>
         <UserStatusAlert user={selected} setUsers={setUsers} modal={statusModal} newStatus={newStatus} />
         <UserForm id={selected?.id} modal={formModal} setSelectedUser={setSelected} />
         <ul>
            {usersRecordList.map((_user, key) => (
               <User
                  user={_user}
                  key={key}
                  setUsers={setUsers}
                  formModal={formModal}
                  setSelected={setSelected}
                  statusModal={statusModal}
                  setNewStatus={setNewStatus}
               />
            ))}
         </ul>
      </>
   );
};

export default Users;
