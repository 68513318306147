import { Attendance, AttendanceValidationError } from "../types";
import stripEmptyObjectProp from "./stripEmptyObjectProp";
import validateBranchCode from "./validators/validateBranchCode";
import validateCount from "./validators/validateCount";
import validateDate from "./validators/validateDate";
import validateEntityName from "./validators/validateEntityName";

export default function validateAttendance(attendance: Attendance) {
   let errors = {} as AttendanceValidationError;
   errors.branchCode = validateBranchCode(attendance.branchCode);
   errors.attendanceDate = validateDate(attendance.attendanceDate);
   errors.serviceName = validateEntityName(attendance.serviceName, "Service");
   errors.male = validateCount(attendance.male, { entity: "Male", optional: false });
   errors.female = validateCount(attendance.female, { entity: "Female", optional: false });
   errors.childrenn = validateCount(attendance.childrenn, { entity: "Children", optional: false });
   errors.salvation = validateCount(attendance.salvation, { optional: true, entity: "Salvation" });
   errors.holySpiritBaptism = validateCount(attendance.holySpiritBaptism, {
      optional: true,
      entity: "Holy Spirit baptism",
   });
   errors.testimony = validateCount(attendance.testimony, {
      optional: true,
      entity: "Testimony",
   });
   errors.prophesy = validateCount(attendance.prophesy, {
      optional: true,
      entity: "Prophesy",
   });
   errors.firstTimer = validateCount(attendance.firstTimer, {
      optional: true,
      entity: "First timer",
   });
   errors.vehicle = validateCount(attendance.vehicle, {
      optional: true,
      entity: "Vehicle",
   });

   return stripEmptyObjectProp(errors);
}
