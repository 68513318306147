import validator from "validator";
import { offeringConfig } from "../../config";

export default function validateCurrency(currency = ""): string {
  const error = [];

  if (typeof currency !== "string") {
    error.push("Invalid currency");
    return error.join(" ");
  }

  if (validator.isEmpty(currency)) {
    error.push("Currency is required");
  } else {
    if (!offeringConfig.allowedCurrencies.includes(currency.toUpperCase()))
      error.push("Currency not allowed");
  }

  return error.join(" ");
}
