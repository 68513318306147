import { useEffect, useState } from "react";
import Pagination from "../../../components/Pagination";
import { Loading } from "../../../components/ui/loading";
import { ResourceLoadingError } from "../../../components/ui/resource-loading-error";
import Workspace from "../../../components/workspace";
import permissionService from "../../../services/permission-service";
import Permissions from "./Permissions";

function PermissionsListContainer({ searchQuery = "" }) {
   const [isLoading, setIsLoading] = useState(true);
   const [errorMessage, setErrorMessage] = useState();
   const [permissions, setPermissions] = useState([]);
   const [page, setPage] = useState(1);
   const [rows] = useState(10);
   const [totalPages, setTotalPages] = useState(1);

   useEffect(() => {
      const loadPermissions = async () => {
         setIsLoading(true);
         try {
            const fetchResult = await permissionService.getPermissions({ page, rows, search: searchQuery });
            setPermissions(fetchResult.data);
            setTotalPages(fetchResult.totalPages);
         } catch (error) {
            setErrorMessage(error.message);
         } finally {
            setIsLoading(false);
         }
      };
      loadPermissions();
   }, [page, rows, searchQuery]);

   if (isLoading) return <Loading />;
   if (errorMessage) return <ResourceLoadingError error={errorMessage} />;
   return (
      <Workspace.ListWrapper>
         <Permissions permissionsList={permissions} />
         {permissions.length ? <Pagination setPage={setPage} page={page} totalPages={totalPages} /> : ""}
      </Workspace.ListWrapper>
   );
}

export default PermissionsListContainer;
