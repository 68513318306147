import { SimpleEntity, SimpleEntityValidationError } from "../../../types";
import stripEmptyObjectProp from "../../../utils/stripEmptyObjectProp";
import validateEntityDescription from "../../../utils/validators/validateEntityDescription";
import validateEntityName from "../../../utils/validators/validateEntityName";

export default function validateGroupType({ name, description }: SimpleEntity): object {
   const errors = {} as SimpleEntityValidationError;
   errors.name = validateEntityName(name, "Group Type");
   errors.description = validateEntityDescription(description, { optional: false, entity: "Group Type" });

   return stripEmptyObjectProp(errors);
}
