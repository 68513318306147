import { endpoints } from "../config/endpoints";
import { axiosPrivateFetch } from "./fetch-service";

async function getSMSHistory({ page, rows }) {
   const url = endpoints.sms;
   const options = { params: { page, rows } };
   return axiosPrivateFetch.get(url, options);
}

async function getSMS(id) {
   return axiosPrivateFetch.get(`${endpoints.sms}/${id}`);
}

export type SMS = { message: String; recipients: String[] };

const sendSMS = async (sms: SMS) => {
   const options = { method: "POST", data: sms, url: endpoints.sms };
   return axiosPrivateFetch(options);
};

const SMSService = {
   getSMSHistory,
   getSMS,
   sendSMS,
};

export default SMSService;
