import { Modal, ModalBody, ModalContent, ModalOverlay, useToast } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { Form, Formik } from "formik";
import { useState } from "react";
import { Button } from "../../../components/ui/button/Button";
import { TextInput } from "../../../components/ui/form";
import Workspace from "../../../components/workspace";
import permissionService from "../../../services/permission-service";
import validatePermission from "../../../utils/validatePermission";

const PermissionForm = ({ modal, id }) => {
   const [successNotificationText, setSuccessNotificationText] = useState("");
   const [submitError, setSubmitError] = useState(null);

   const { isOpen, onClose } = modal;
   const heading = id ? "Edit permission record" : "Add new permission";
   const controller = new AbortController();
   const signal = controller.signal;

   const toast = useToast();

   const loadPermissionDetails = async () => {
      if (!id) return Promise.resolve({});
      try {
         const fetchResult = await permissionService.getPermission({ id, signal });
         return fetchResult.data;
      } catch (error) {
         toast({ status: "error", description: error.message, title: "Fetching members.", isClosable: true });
         throw error;
      }
   };

   const {
      data: permission = {},
      isLoading,
      error,
   } = useQuery({ queryKey: ["permissions", id], queryFn: loadPermissionDetails });

   const handleSubmit = async (values) => {
      try {
         const submitResult = await permissionService.submitPermissionMaintenance({
            permission: values,
            editOrCreate: id ? "edit" : "create",
         });
         setSuccessNotificationText(submitResult.message);
      } catch (error) {
         setSubmitError(error.message);
      }
   };

   if (isLoading) return <Workspace.LoadingModal modal={modal} controller={controller} heading={heading} />;

   if (successNotificationText)
      return (
         <Workspace.SuccessNotificationModal
            modal={modal}
            message={successNotificationText}
            callback={() => {
               setSubmitError(null);
               setSuccessNotificationText(null);
            }}
         />
      );

   if (error) return <Workspace.FetchErrorModal modal={modal} message={error} heading={heading} />;

   return (
      <Modal isOpen={isOpen} onClose={onClose} size="2xl">
         <ModalOverlay />
         <ModalContent className="!rounded-2xl">
            <Workspace.ModalHeader>
               <Workspace.ModalHeaderWithCloseButton>{heading}</Workspace.ModalHeaderWithCloseButton>
            </Workspace.ModalHeader>
            <ModalBody className="grid">
               <div className="py-5 px-4">
                  <div className="flex flex-wrap items-center">
                     <div className="w-full ">
                        <Formik
                           initialValues={permission}
                           validate={validatePermission}
                           onSubmit={handleSubmit}
                           enableReinitialize>
                           {({ values, setFieldValue, isSubmitting, handleBlur }) => (
                              <Form className="grid gap-6">
                                 <TextInput
                                    label="Permission name"
                                    id="permissionName"
                                    name="name"
                                    type="text"
                                    placeholder="Enter permission name"
                                    required
                                 />
                                 <TextInput
                                    label="Permission description"
                                    id="permissionDescription"
                                    name="description"
                                    type="text"
                                    placeholder="Enter permission description"
                                    required
                                 />

                                 {submitError && (
                                    <div className="mb-5">
                                       <Workspace.FormSubmitError>{submitError}</Workspace.FormSubmitError>
                                    </div>
                                 )}

                                 <div className="mb-3 flex justify-end gap-2">
                                    <Button
                                       type="reset"
                                       variant="secondary"
                                       onClick={() => {
                                          controller.abort();
                                          onClose();
                                       }}>
                                       Cancel
                                    </Button>
                                    <Button variant="primary" type="submit" className="btn btn-primary" disabled={isSubmitting}>
                                       {id ? "Edit permission" : "Create new permission"}
                                    </Button>
                                 </div>
                              </Form>
                           )}
                        </Formik>
                     </div>
                  </div>
               </div>
            </ModalBody>
         </ModalContent>
      </Modal>
   );
};

export { PermissionForm };
