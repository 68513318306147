import { useDisclosure } from "@chakra-ui/react";
import { useState } from "react";
import EmptyList from "../../../components/ui/EmptyList";
import Role from "./Role";
import RoleForm from "./RoleForm";

const Roles = ({ roles = [] }) => {
   const [selectedRole, setSelectedRole] = useState(null);
   const formModal = useDisclosure();

   if (!roles.length) return <EmptyList message="Roles would be listed here" />;
   return (
      <>
         <RoleForm modal={formModal} id={selectedRole?.id} setSelectedRole={setSelectedRole} />
         <ul>
            {roles.map((_role, key) => (
               <Role role={_role} key={key} formModal={formModal} setSelectedRole={setSelectedRole} />
            ))}
         </ul>
      </>
   );
};

export default Roles;
