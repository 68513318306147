import { Modal, ModalBody, ModalContent, ModalFooter, ModalOverlay } from "@chakra-ui/react";
import { DefaultBadge } from "../../components/ui/badge";
import { Button } from "../../components/ui/button/Button";

const SMSDetails = ({ modal, sms, SMSStatusBadge }) => {
   const { isOpen, onClose } = modal;
   return (
      <Modal isOpen={isOpen} onClose={onClose} size="lg">
         <ModalOverlay />
         <ModalContent className="!rounded-2xl p-4 mx-4">
            <ModalBody className="text-center my-8 w-4/5 mx-auto">
               <p className="text-2xl font-semibold">{sms.receiver}</p>
               <p>
                  <i className="text-xs">{new Date(sms.createdAt).toDateString()}</i>
               </p>
               <div className="flex justify-center gap-1 mt-4 mb-2">
                  {sms.groupId ? <DefaultBadge>Group</DefaultBadge> : null}
                  {sms.bulkId ? <DefaultBadge>Bulk</DefaultBadge> : null}
                  <SMSStatusBadge sms={sms} />
               </div>
               <div className="py-3 -mx-5 text-justify">{sms.message}</div>
            </ModalBody>
            <ModalFooter>
               <div className="grid w-full md:w-11/12 mx-auto">
                  <Button variant="primary" className="btn btn-primary btn-lg !py-4" onClick={onClose}>
                     OK
                  </Button>
               </div>
            </ModalFooter>
         </ModalContent>
      </Modal>
   );
};

export default SMSDetails;
