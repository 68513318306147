import { BiPencil } from "react-icons/bi";
import { PaddedIcon } from "../../components/ui/button/Icon";
import Workspace from "../../components/workspace";

const Branch = ({ branch, formModal, setSelected }) => {
   return (
      <Workspace.ItemWrapper
         onClick={() => {
            setSelected(branch);
            formModal.onOpen();
         }}>
         <div className="flex justify-between items-center px-4 py-5">
            <input type="checkbox" className="me-2 md:me-4" onClick={(e) => e.stopPropagation()} />
            <div className="mx-2 w-full overflow-hidden overflow-ellipsis">
               <p className="font-bold" title="Branch name">
                  {branch?.branchName}
               </p>
               <small title="Branch resident pastor">{branch?.residentPastor}</small>
            </div>
            <small className="w-3/12">{branch?.branchCode}</small>

            <Workspace.ItemActionsWrapper>
               <PaddedIcon variant="primary" aria-label="edit">
                  <BiPencil className="text-lg" />
               </PaddedIcon>
            </Workspace.ItemActionsWrapper>
         </div>
      </Workspace.ItemWrapper>
   );
};

export default Branch;
