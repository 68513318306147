import { endpoints } from "../config/endpoints";
import { axiosPrivateFetch } from "./fetch-service";

function getIndicators() {
  return axiosPrivateFetch.get(endpoints.dashboard);
}

const dashboardService = { getIndicators };

export default dashboardService;
