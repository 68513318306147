import "../../styles/loading.css";

const Loading = () => {
   return (
      <div className="loading min-h-[40vh] flex items-center justify-center [&>*]:w-4 [&>*]:aspect-square">
         <span className="bg-[--clr-primary] mx-1 rounded-full"></span>
         <span className="bg-[--clr-primary] mx-1 rounded-full"></span>
         <span className="bg-[--clr-primary] mx-1 rounded-full"></span>
      </div>
   );
};

export { Loading };
