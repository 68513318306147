import { useDisclosure } from "@chakra-ui/react";
import { useState } from "react";
import { BiEnvelope } from "react-icons/bi";
import { Button } from "../../components/ui/button/Button";
import { default as Workspace } from "../../components/workspace";
import SMSForm from "./sms-form";
import SMSList from "./sms-list";

const SMSManagementPage = () => {
   const [page, setPage] = useState(1);
   const newSMSModal = useDisclosure();

   return (
      <>
         <SMSForm modal={newSMSModal} refresh={() => setPage(0)} />
         <Workspace.TopBar heading="SMS Management" />
         <Workspace.WorkspaceActions>
            <Button variant="primary" onClick={newSMSModal.onOpen}>
               <span className="inline-block text-xl me-2">
                  <BiEnvelope />
               </span>
               Compose new SMS
            </Button>
         </Workspace.WorkspaceActions>
         <Workspace.ComponentWrapper>
            <Workspace.WorkspaceHeader>
               <Workspace.WorkspaceHeaderWrapper>
                  <Workspace.WorkspaceSubHeading>SMS History</Workspace.WorkspaceSubHeading>
               </Workspace.WorkspaceHeaderWrapper>
            </Workspace.WorkspaceHeader>
            <SMSList page={page} setPage={setPage} />
         </Workspace.ComponentWrapper>
      </>
   );
};

export default SMSManagementPage;
