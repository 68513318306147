import { FieldConfig, useField } from "formik";
import { ClassAttributes, InputHTMLAttributes } from "react";

type InputProp = ClassAttributes<HTMLInputElement> &
   InputHTMLAttributes<HTMLInputElement> &
   FieldConfig<any> & { label?: string };

type TextAreaProp = ClassAttributes<HTMLTextAreaElement> &
   InputHTMLAttributes<HTMLTextAreaElement> &
   FieldConfig<any> & { label?: string };

const TextInput = ({ label, ...props }: InputProp) => {
   const [field, meta] = useField(props);
   return (
      <div className="form-group | ">
         {label && <FieldLabel htmlFor={`${props.id || props.name}`}>{label}</FieldLabel>}
         <input
            className="form-control | border border-[#ced4da] rounded overflow-hidden text-[0.8rem] w-full px-4 py-[0.7rem]"
            {...field}
            {...props}
         />
         {meta.touched && meta.error ? <FieldError className="mt-1">{meta.error}</FieldError> : null}
      </div>
   );
};

const FieldError = ({ children, className }) => {
   return <span className={`form-error | inline-block text-red-500 text-sm ${className || ""}`}>{children}</span>;
};

const FieldLabel = ({ children, htmlFor }) => {
   return (
      <label htmlFor={htmlFor} className="form-label | block mb-1 text-sm text-gray-600 font-bold">
         {children}
      </label>
   );
};

const TextArea = ({ label, className, ...props }: TextAreaProp) => {
   const [field, meta] = useField(props);
   return (
      <div className="form-group | ">
         {label && <FieldLabel htmlFor={`${props.id || props.name}`}>{label}</FieldLabel>}
         <textarea
            className={`form-control | border border-[#ced4da] rounded overflow-hidden text-[0.8rem] w-full px-4 py-[0.7rem] ${className}`}
            {...field}
            {...props}
         />
         {meta.touched && meta.error ? <FieldError className="mt-1">{meta.error}</FieldError> : null}
      </div>
   );
};

const CheckboxListItem = ({ children, ...props }: InputProp) => {
   const [field] = useField(props);

   return (
      <label className="flex w-full items-center p-2 cursor-pointer border-b first:border-t">
         <input className="me-1" type="checkbox" {...field} {...props} />
         {children}
      </label>
   );
};

export { CheckboxListItem, FieldError, FieldLabel, TextArea, TextInput };
