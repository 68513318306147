import { endpoints } from "../config/endpoints";
import { axiosPrivateFetch } from "./fetch-service";

async function getAttendances({ page, rows, signal }) {
   const url = endpoints.attendances;
   const options = { params: { page, rows } };
   return axiosPrivateFetch.get(url, options);
}

async function getAttendance(id, signal) {
   return axiosPrivateFetch.get(`${endpoints.attendances}/${id}`);
}

async function submitAttendanceMaintenance({ attendance, editOrCreate, signal }) {
   const options =
      editOrCreate?.toLowerCase() === "edit"
         ? {
              method: "PUT",
              data: attendance,
              url: `${endpoints.attendances}/${attendance.id}`,
              signal,
           }
         : { method: "POST", data: attendance, url: endpoints.attendances, signal };

   return axiosPrivateFetch(options);
}

const attendanceService = {
   getAttendances,
   getAttendance,
   submitAttendanceMaintenance,
};

export default attendanceService;
