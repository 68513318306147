import { Navigate, Outlet, useLocation } from "react-router-dom";
import tokenService from "../services/token-service";

const RequireAuth = () => {
  const location = useLocation();

  return tokenService.getAccessToken() ? (
    <Outlet />
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};

export default RequireAuth;
