import { Outlet } from "react-router-dom";
import Sidebar from "./components/sidebar/Sidebar";

export default function MainLayout() {
   return (
      <>
         <Sidebar />
         <div className="main-content | flex w-full min-h-full grow px-4 lg:ms-5">
            <div className="flex flex-col w-full">
               <Outlet />
            </div>
         </div>
      </>
   );
}
