import React from "react";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: undefined, errorInfo: undefined };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error,
      errorInfo: errorInfo,
    });
  }

  render() {
    if (this.state.errorInfo) {
      return (
        <div className="grid w-full place-content-center p-5">
          <div className="mb-5 text-center">
            <div className="fw-700 text-7xl text-warning">500</div>
            <p className="text-3xl">Oops! Something went wrong.</p>
            <p>This is our fault, not yours. Please try again.</p>
          </div>
          {process.env.NODE_ENV === "development" ? (
            <details style={{ whiteSpace: "pre-wrap" }}>
              {this.state.error && this.state.error.toString()}
              <br />
              {this.state.errorInfo.componentStack}
            </details>
          ) : null}
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
