import validator from "validator";
import { offeringConfig } from "../../config";

export default function validateOfferingType(offeringType = ""): string {
  const error = [];

  if (validator.isEmpty(offeringType)) {
    error.push("Offering type is required");
  } else {
    if (!offeringConfig.types.includes(offeringType.toUpperCase()))
      error.push("Offering type not allowed");
  }

  return error.join(" ");
}
