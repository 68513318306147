import validator from "validator";

export default function validateCount(count = "", { optional, entity }: { optional: boolean; entity: string }) {
   const error = [];

   if (!optional || count) {
      if (count == null) {
         error.push(entity + " count is required");
      } else {
         if (!validator.isInt(count + ""))
            error.push("Invalid " + entity.toLowerCase() + " count. Only integers are allowed");
      }
   }

   return error.join(" ");
}
