import { useField } from "formik";
import { FieldError, FieldLabel } from ".";

const SelectInput = ({ label, ...props }) => {
   const [field, meta] = useField(props);
   return (
      <div className="form-group | ">
         {label ? <FieldLabel htmlFor={props.id || props.name}>{label}</FieldLabel> : null}
         <select
            className="form-select | border border-[#ced4da] rounded overflow-hidden text-[0.8rem] w-full px-4 py-[0.7rem]"
            {...field}
            {...props}
         />
         {meta.touched && meta.error ? <FieldError className="mt-1">{meta.error}</FieldError> : null}
      </div>
   );
};

export { SelectInput };
