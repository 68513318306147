import { useEffect, useState } from "react";
import Pagination from "../../../components/Pagination";
import { Loading } from "../../../components/ui/loading";
import { ResourceLoadingError } from "../../../components/ui/resource-loading-error";
import Workspace from "../../../components/workspace";
import userService from "../../../services/user-service";
import Users from "./Users";

const UsersListContainer = ({ search = "" }) => {
   const [isLoading, setIsLoading] = useState(true);
   const [errorMessage, setErrorMessage] = useState(undefined);
   const [users, setUsers] = useState([]);
   const [page, setPage] = useState(1);
   const [rows] = useState(10);
   const [totalPages, setTotalPages] = useState(1);

   useEffect(() => {
      const loadUsers = async () => {
         setIsLoading(true);
         try {
            const fetchResult = await userService.getUsers({ page, rows, search });
            setUsers(fetchResult.data);
            setTotalPages(fetchResult.totalPages);
         } catch (error) {
            setErrorMessage(error.message);
         } finally {
            setIsLoading(false);
         }
      };
      loadUsers();
   }, [page, rows, search]);

   if (isLoading) return <Loading />;
   if (errorMessage) return <ResourceLoadingError error={errorMessage} />;

   return (
      <Workspace.ListWrapper>
         <Users usersRecordList={users} setUsers={setUsers} />
         {users.length ? <Pagination setPage={setPage} page={page} totalPages={totalPages} /> : ""}
      </Workspace.ListWrapper>
   );
};

export default UsersListContainer;
