import { Td, Tr, useDisclosure } from "@chakra-ui/react";
import { BiPencil } from "react-icons/bi";
import { BsThreeDotsVertical } from "react-icons/bs";
import { PaddedIcon } from "../../components/ui/button/Icon";
import Workspace from "../../components/workspace";
import { useFeatureFlag } from "../../state/featureFlagContext";
import { AttendanceForm } from "./attendance-form";

const Attendance = ({ attendance }) => {
   const modificationModal = useDisclosure();
   const { displayWorkspaceMenuButton } = useFeatureFlag();

   return (
      <>
         {modificationModal.isOpen ? <AttendanceForm modal={modificationModal} id={attendance.id} /> : null}
         <Workspace.ItemWrapper
            onClick={() => {
               modificationModal.onOpen();
            }}>
            <div className="flex justify-between items-center px-4 py-5">
               <input type="checkbox" className="me-2 md:me-4" onClick={(e) => e.stopPropagation()} />
               <div className="mx-2 w-full font-bold overflow-hidden overflow-ellipsis">
                  <p className="m-0">{`${attendance.attendanceDate} | ${attendance.serviceName}`}</p>
                  <small>
                     <b>Male: </b>
                     <i>{attendance.male}&nbsp; </i>
                  </small>
                  <small>
                     <b>Female: </b>
                     <i>{attendance.female}&nbsp; </i>
                  </small>
                  <small>
                     <b>Children: </b>
                     <i>{attendance.children}</i>
                  </small>
               </div>

               <p className="w-2/12" title="Attendance branch">
                  {attendance.branchCode}
               </p>

               <p className="w-2/12" title="Total attendees">
                  {Number(attendance.male || 0) + Number(attendance.female || 0) + Number(attendance.children || 0)}
               </p>

               <div className="workspace__actions flex justify-end flex-wrap w-1/12 md:w-3/12 ms-4">
                  <PaddedIcon variant="primary" title="Edit audio record" aria-label="edit">
                     <BiPencil />
                  </PaddedIcon>
                  {displayWorkspaceMenuButton ? (
                     <PaddedIcon
                        variant="secondary"
                        title="Upload album art"
                        aria-label="album-art"
                        onClick={(e) => {
                           e.stopPropagation();
                        }}>
                        <BsThreeDotsVertical />
                     </PaddedIcon>
                  ) : null}
               </div>
            </div>
         </Workspace.ItemWrapper>
      </>
   );
};

export { Attendance };

// Not in use yet.
const AttendanceV1 = ({ attendance }) => {
   const { displayWorkspaceMenuButton } = useFeatureFlag();

   return (
      <Tr
         className="workspace__li | last:mb-8 border-b-[1px] border-b-[#ddd] cursor-pointer hover:bg-[#dfefff] focus:bg-[#dfefff] active:bg-[#acd]"
         onClick={() => {}}>
         <Td className="">
            <input type="checkbox" className="w-4 h-4" onClick={(e) => e.stopPropagation()} />
         </Td>
         <Td className="w-full !ps-0 bg-green-4000">
            <div className="font-bold overflow-hidden overflow-ellipsis">
               <p className="m-0">{`${attendance.attendanceDate} | ${attendance.serviceName}`}</p>
               <small>
                  <b>Male: </b>
                  <i>{attendance.male}&nbsp; </i>
               </small>
               <small>
                  <b>Female: </b>
                  <i>{attendance.female}&nbsp; </i>
               </small>
               <small>
                  <b>Children: </b>
                  <i>{attendance.children}</i>
               </small>
            </div>
         </Td>

         <Td className="!text-center" title="Attendance branch">
            {attendance.branchCode}
         </Td>

         <Td className="!text-center" title="Total attendees">
            {Number(attendance.male || 0) + Number(attendance.female || 0) + Number(attendance.children || 0)}
         </Td>

         <Td className="workspace__actions | flex gap-2">
            <PaddedIcon variant="primary" title="Edit audio record" aria-label="edit">
               <BiPencil className="text-lg" />
            </PaddedIcon>

            {displayWorkspaceMenuButton ? (
               <PaddedIcon
                  variant="secondary"
                  title="Upload album art"
                  aria-label="album-art"
                  onClick={(e) => {
                     e.stopPropagation();
                  }}>
                  <BsThreeDotsVertical />
               </PaddedIcon>
            ) : null}
         </Td>
      </Tr>
   );
};

export { AttendanceV1 };
