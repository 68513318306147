import { Branch, BranchValidationError } from "../types";
import stripEmptyObjectProp from "./stripEmptyObjectProp";
import validateAddress from "./validators/validateAddress";
import validateBranchCode from "./validators/validateBranchCode";
import validateEntityName from "./validators/validateEntityName";
import { validateFullName } from "./validators/validateFullName";
import validatePhoneNumber from "./validators/validatePhoneNumber";

export default function validateBranch(branch: Branch) {
  let errors = {} as BranchValidationError;
  errors.branchCode = validateBranchCode(branch.branchCode);
  errors.branchName = validateEntityName(branch.branchName, "Branch");
  errors.residentPastor = validateFullName(branch.residentPastor, {
    entity: "Resident pastor",
    optional: true,
  });
  errors.phoneNo = validatePhoneNumber(branch.phoneNo);
  errors.physicalAddress = validateAddress(branch.physicalAddress);

  return stripEmptyObjectProp(errors);
}
