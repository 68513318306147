import { Modal, ModalBody, ModalContent, ModalOverlay } from "@chakra-ui/react";
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { Button } from "../../components/ui/button/Button";
import { TextInput } from "../../components/ui/form";
import { SelectInput } from "../../components/ui/form/SelectInput";
import Workspace from "../../components/workspace";
import authService from "../../services/auth-service";
import offeringService from "../../services/offering-service";
import { useBranches } from "../../state/branchListContext";
import { sortRecordsByObjectProp } from "../../utils/stripEmptyObjectProp";
import validateOffering from "../../utils/validateOffering";

const OfferingForm = ({ modal, id, setSelected = () => {} }) => {
   const { branchCode } = authService.getUserInfo();
   const { branches } = useBranches();

   const [offering, setOffering] = useState({
      offeringTye: "",
      currency: "",
      offeringBranchCode: branchCode,
      sourceCode: "CASH",
      offeringDate: new Date().toJSON().slice(0, 10),
      amount: "",
      note: "",
   });

   const [isLoading, setIsLoading] = useState(id);
   const [successNotificationText, setSuccessNotificationText] = useState(null);
   const [fetchError, setFetchError] = useState("");
   const [submitError, setSubmitError] = useState();

   const heading = id ? "Edit Offering Record" : "Register Offering";
   const controller = new AbortController();
   const signal = controller.signal;

   useEffect(() => {
      async function loadOfferingDetails() {
         try {
            const fetchResult = await offeringService.getOffering({ id });
            setOffering(fetchResult.data);
         } catch (error) {
            setFetchError(error.message);
         } finally {
            setIsLoading(false);
         }
      }
      id && loadOfferingDetails();

      return () => {
         setFetchError("");
         setIsLoading(id);
      };
   }, [id]);

   const handleSubmit = async (offering) => {
      setSubmitError(null);

      try {
         const submitResult = await offeringService.submitOfferingMaintenance({
            offering,
            editOrCreate: id ? "edit" : "create",
         });
         setSuccessNotificationText(submitResult.message);
      } catch (error) {
         setSubmitError(error.message);
      }
   };

   const reset = () => {
      setSuccessNotificationText(null);
      setOffering({
         offeringBranchCode: branchCode,
         sourceCode: "CASH",
         offeringDate: new Date().toJSON().slice(0, 10),
         amount: "",
         note: "",
      });
      setSubmitError(null);
      setSelected({});
   };

   if (isLoading) return <Workspace.LoadingModal controller={controller} heading={heading} modal={modal} />;
   if (fetchError) return <Workspace.FetchErrorModal modal={modal} heading={heading} errorMessage={fetchError} callback={reset} />;
   if (successNotificationText) return <Workspace.SuccessNotificationModal modal={modal} message={successNotificationText} callback={reset} />;

   return (
      <Modal isOpen={modal.isOpen} onClose={modal.onClose} size="2xl">
         <ModalOverlay />
         <ModalContent className="!rounded-2xl">
            <Workspace.ModalHeader>
               <Workspace.ModalHeaderWithCloseButton>{heading}</Workspace.ModalHeaderWithCloseButton>
            </Workspace.ModalHeader>
            <ModalBody className="grid">
               <div className="py-5 px-4">
                  <div className="flex flex-wrap items-center">
                     <div className="w-full">
                        <Formik initialValues={offering} validate={validateOffering} onSubmit={handleSubmit} enableReinitialize>
                           {({ values, isSubmitting }) => (
                              <Form className="grid gap-6">
                                 <TextInput type="hidden" id="sourceCode" name="sourceCode" required />
                                 <SelectInput
                                    label="Offering Branch"
                                    id="offeringBranchCode"
                                    name="offeringBranchCode"
                                    required
                                    disabled={id ? values.offeringBranchCode : true}>
                                    <option value="">Select branch</option>
                                    {branches &&
                                       sortRecordsByObjectProp(branches, "branchCode").map((_branch, key) => (
                                          <option value={_branch.branchCode} key={key}>
                                             {`${_branch.branchCode} - ${_branch.branchName}`}
                                          </option>
                                       ))}
                                 </SelectInput>
                                 <SelectInput label="Offering Type" id="offeringType" name="offeringType" required>
                                    <option value=" ">Select offering type</option>
                                    <option value="TITHE">Tithe</option>
                                    <option value="GENERAL OFFERING">General Offering</option>
                                    <option value="THANKSGIVING">Thanksgiving</option>
                                    <option value="SEED">Seed</option>
                                    <option value="PROJECT">Project</option>
                                 </SelectInput>
                                 <SelectInput label="Currency" id="currency" name="currency" required>
                                    <option value=" ">Select offering currency</option>
                                    <option value="NGN">NGN</option>
                                    <option value="USD">USD</option>
                                 </SelectInput>
                                 <TextInput type="date" label="Offering Date" id="offeringDate" name="offeringDate" required />
                                 <TextInput type="number" label="Amount" id="amount" name="amount" placeholder="Enter amount here" required />
                                 <TextInput type="text" label="Note" id="note" name="note" placeholder="Additional note" />

                                 {submitError ? (
                                    <div className="mb-5">
                                       <Workspace.FormSubmitError>{submitError}</Workspace.FormSubmitError>
                                    </div>
                                 ) : null}
                                 <div className="mb-3 flex justify-end gap-2">
                                    <Button
                                       type="reset"
                                       variant="secondary"
                                       onClick={() => {
                                          controller.abort();
                                          modal.onClose();
                                       }}>
                                       Cancel
                                    </Button>
                                    <Button variant="primary" type="submit" className="btn btn-primary" disabled={isSubmitting}>
                                       {!Boolean(id) ? "Add offering" : "Edit offering"}
                                    </Button>
                                 </div>
                              </Form>
                           )}
                        </Formik>
                     </div>
                  </div>
               </div>
            </ModalBody>
         </ModalContent>
      </Modal>
   );
};

export default OfferingForm;
