import validator from "validator";

const ALLOWED_SPECIAL_CHARACTERS = " -,:()'";

export default function validateTitle(title = ""): string {
  const error = [];

  if (validator.isEmpty(title)) {
    error.push("Title is required");
  } else {
    if (!validator.isLength(title, { min: 2, max: 100 })) {
      error.push("Only 2 - 100 characters allowed for title");
    }
    if (!validator.isAlphanumeric(title, "en-GB", { ignore: ALLOWED_SPECIAL_CHARACTERS })) {
      error.push(
        `Only alphanumeric character and ${ALLOWED_SPECIAL_CHARACTERS} are allowed for title`
      );
    }
  }

  return error.join(" ");
}
