import { BiPencil } from "react-icons/bi";
import { PaddedIcon } from "../../../components/ui/button/Icon";
import Workspace from "../../../components/workspace";

const GroupType = ({ groupType, formModal, setSelected }) => {
   return (
      <Workspace.ItemWrapper
         onClick={() => {
            setSelected(groupType);
            formModal.onOpen();
         }}>
         <div className="flex justify-between items-center px-4 py-5">
            <input type="checkbox" className="me-2 md:me-4" onClick={(e) => e.stopPropagation()} />
            <p className="mx-2 w-full font-bold overflow-hidden overflow-ellipsis">{`${groupType.name}`}</p>
            <p className="w-1/2 md:w-1/2 text-sm">{groupType.description}</p>

            <Workspace.ItemActionsWrapper>
               <PaddedIcon variant="primary" aria-label="edit">
                  <BiPencil className="text-lg" />
               </PaddedIcon>
            </Workspace.ItemActionsWrapper>
         </div>
      </Workspace.ItemWrapper>
   );
};

export { GroupType };
