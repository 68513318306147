import validator from "validator";

export default function stripEmptyObjectProp(obj: any) {
  for (let prop in obj) {
    if (obj.hasOwnProperty(prop) && (obj[prop] == null || obj[prop] === "")) delete obj[prop];
  }
  return obj;
}

export function sortRecordsByObjectProp<T>(
  records: T[],
  sortingProp: string,
  { desc }: { desc?: boolean } = { desc: false }
) {
  if (!sortingProp) throw new Error("sortingProp is required. Object property to sort by");
  return records.sort((a, b) => {
    let propA = "",
      propB = "";
    if (!a[sortingProp] || !b[sortingProp]) {
      propA = "";
      propB = "";
    } else {
      propA = a[sortingProp];
      propB = b[sortingProp];

      if (desc) {
        [propA, propB] = [propB, propA];
      }
      if (typeof propA === "string") {
        propA = propA.toUpperCase();
        propB = propB.toUpperCase();
      }
    }
    if (propA < propB) {
      return -1;
    }
    if (propA > propB) {
      return 1;
    }
    return 0;
  });
}

export function sanitize(input: string) {
  const MAX_CHARACTERS_ALLOWED = 50;
  if (input.length > MAX_CHARACTERS_ALLOWED)
    throw new Error("Max characters allowed is " + MAX_CHARACTERS_ALLOWED);
  return validator.whitelist(input, "0-9a-zA-Z @");
}
