import { AxiosRequestConfig } from "axios";
import { endpoints } from "../../../config/endpoints";
import { axiosPrivateFetch } from "../../../services/fetch-service";
import { ApiResponse } from "../../../types";
import makeExcelReportFile from "../../../utils/make-report-file";

const getMembers = async ({ page, rows, search }: { page: number; rows: number; search?: string }) => {
   return axiosPrivateFetch.get(endpoints.members, { params: { page, rows, search } });
};

const getAllMembers = async () => {
   return axiosPrivateFetch.get(`${endpoints.members}/all`);
};

async function getMember(id: string) {
   return axiosPrivateFetch.get(`${endpoints.members}/${id}`);
}

const submitMemberMaintenance = async ({ member, editOrCreate }) => {
   const options: AxiosRequestConfig =
      editOrCreate === "edit"
         ? {
              method: "PUT",
           }
         : { method: "POST" };
   options.data = member;

   const url = editOrCreate === "edit" ? `${endpoints.members}/${member.id}` : endpoints.members;

   return axiosPrivateFetch(url, options);
};

async function downloadMembers() {
   const result = (await axiosPrivateFetch.get(`${endpoints.membersReport}`)) as unknown as ApiResponse;
   return makeExcelReportFile({
      data: result.data,
      headers: result.reportHeaders,
      reportName: "Members_report",
   });
}

const memberService = { getMembers, getAllMembers, getMember, submitMemberMaintenance, downloadMembers };

export default memberService;
