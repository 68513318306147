import { useDisclosure } from "@chakra-ui/react";
import { BiPlus } from "react-icons/bi";
import { Button } from "../../components/ui/button/Button";
import Workspace from "../../components/workspace";
import { AttendanceListContainer } from "./AttendanceListContainer";
import { AttendanceForm } from "./attendance-form";

const TOP_BAR_HEADING = "Attendance Management";
const SUB_HEADING_TITLE = "Attendance Records";

const AttendanceManagement = () => {
   const formModal = useDisclosure();

   return (
      <>
         <AttendanceForm modal={formModal} />
         <Workspace.TopBar heading={TOP_BAR_HEADING} />
         <Workspace.WorkspaceActions>
            <Button variant="primary" onClick={formModal.onOpen}>
               <span className="inline-flex items-center gap-2">
                  <BiPlus />
                  Add new record
               </span>
            </Button>
         </Workspace.WorkspaceActions>
         <Workspace.ComponentWrapper>
            <Workspace.WorkspaceHeader>
               <Workspace.WorkspaceHeaderWrapper>
                  <Workspace.WorkspaceSubHeading>{SUB_HEADING_TITLE}</Workspace.WorkspaceSubHeading>
               </Workspace.WorkspaceHeaderWrapper>
            </Workspace.WorkspaceHeader>
            <AttendanceListContainer />
         </Workspace.ComponentWrapper>
      </>
   );
};

export { AttendanceManagement };
