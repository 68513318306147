import { BiChevronLeft, BiChevronRight, BiChevronsLeft, BiChevronsRight } from "react-icons/bi";
import { PaddedIcon } from "./ui/button/Icon";

const PaginationButton = ({ children, ...rest }) => {
   return (
      <PaddedIcon
         className="pagination__btn | text-[#aaa] rounded-full hover:[&:not(:disabled)]:bg-primary hover:[&:not(:disabled)]:text-white transition-all"
         type="button"
         tabIndex="0"
         as="button"
         {...rest}>
         {children}
      </PaddedIcon>
   );
};

const Pagination = ({ setPage, page, totalPages }) => {
   return (
      <div className="pagination flex justify-center">
         <div className="pagination__container | border border-[#ddd] rounded-[2rem] flex gap-x-1 items-center p-1">
            <PaginationButton disabled={page <= 1} onClick={() => setPage(1)}>
               <BiChevronsLeft className="text-2xl" />
            </PaginationButton>
            <PaginationButton
               disabled={page <= 1}
               onClick={() => setPage((prevPage) => (prevPage > 1 ? prevPage - 1 : 1))}>
               <BiChevronLeft className="text-2xl" />
            </PaginationButton>
            <select
               className="mx-2 p-2 border border-[#ddd] rounded-md"
               onChange={(e) => setPage(+e.target.value)}
               value={page}>
               {[...Array(totalPages)].map((e, key) => (
                  <option value={key + 1} key={key}>
                     {key + 1}
                  </option>
               ))}
            </select>
            <i className="me-2">of {totalPages}</i>
            <PaginationButton
               icon="bi bi-chevron-right"
               disabled={page === totalPages}
               onClick={() => setPage((prevPage) => (prevPage < totalPages ? prevPage + 1 : totalPages))}>
               <BiChevronRight className="text-2xl" />
            </PaginationButton>
            <PaginationButton
               icon="bi bi-chevron-double-right"
               disabled={page === totalPages}
               onClick={() => setPage(totalPages)}>
               <BiChevronsRight className="text-2xl" />
            </PaginationButton>
         </div>
      </div>
   );
};

export default Pagination;
