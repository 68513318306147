import { useDisclosure } from "@chakra-ui/react";
import { useState } from "react";
import { BiPlus } from "react-icons/bi";
import { Button } from "../../../components/ui/button/Button";
import Workspace from "../../../components/workspace";
import RoleForm from "./RoleForm";
import RoleListContainer from "./RoleListContainer";

const RoleManagementPage = () => {
   const [searchQuery, setSearchQuery] = useState("");
   const formModal = useDisclosure();

   return (
      <>
         <RoleForm modal={formModal} />
         <Workspace.TopBar heading="Role Management" />
         <Workspace.WorkspaceActions>
            <Button variant="primary" onClick={formModal.onOpen}>
               <span className="inline-flex items-center gap-2">
                  <BiPlus />
                  Create new role
               </span>
            </Button>
         </Workspace.WorkspaceActions>
         <Workspace.ComponentWrapper>
            <Workspace.WorkspaceHeader>
               <Workspace.WorkspaceHeaderWrapper>
                  <Workspace.WorkspaceSubHeading>Roles</Workspace.WorkspaceSubHeading>
                  <Workspace.WorkspaceHeaderSearch>
                     <Workspace.SearchForm onSubmit={setSearchQuery} />
                  </Workspace.WorkspaceHeaderSearch>
               </Workspace.WorkspaceHeaderWrapper>
            </Workspace.WorkspaceHeader>
            <RoleListContainer searchQuery={searchQuery} />
         </Workspace.ComponentWrapper>
      </>
   );
};

export default RoleManagementPage;
