import { useDisclosure } from "@chakra-ui/react";
import { useState } from "react";
import EmptyList from "../../../components/ui/EmptyList.jsx";
import { DeleteGroupDialog } from "./delete-group.jsx";
import { GroupType } from "./group-type";
import { GroupTypeForm } from "./group-type-form.jsx";

const GroupTypes = ({ groupTypes = [], refreshData }) => {
   const [selected, setSelected] = useState<{ id: string }>({ id: "" });
   const formModal = useDisclosure();
   const deleteGroupTypeDialog = useDisclosure();

   if (!groupTypes?.length) return <EmptyList message="Groups would be listed here" />;

   return (
      <>
         <DeleteGroupDialog modal={deleteGroupTypeDialog} groupTypeId={selected?.id} refreshData={refreshData} />
         <GroupTypeForm id={selected?.id} modal={formModal} setSelected={setSelected} />
         <ul>
            {groupTypes.map((_groupType, key) => (
               <GroupType groupType={_groupType} key={key} setSelected={setSelected} formModal={formModal} />
            ))}
         </ul>
      </>
   );
};

export { GroupTypes };
