import { DefaultBadge } from "../../components/ui/badge";
import Workspace from "../../components/workspace";

const SMS = ({ sms, modal, SMSStatusBadge, setSelected }) => {
   return (
      <>
         <Workspace.ItemWrapper
            onClick={() => {
               setSelected(sms);
               modal.onOpen();
            }}>
            <div className="flex justify-between items-center px-4 py-5">
               <div className="mx-2 w-full overflow-hidden overflow-ellipsis">
                  <p className="font-bold">{`${sms.receiver}`}</p>
                  <p className="mb-2">{sms.message.slice(0, 50)}...</p>
                  <small>
                     <span className="font-semibold">Date:</span>
                     <i>{new Date(sms.createdAt).toDateString()}</i>
                  </small>
               </div>

               <div className="flex gap-1 items-center" title="Delivery status">
                  {sms.groupId ? <DefaultBadge>Group</DefaultBadge> : null}
                  {sms.bulkId ? <DefaultBadge>Bulk</DefaultBadge> : null}
                  <SMSStatusBadge sms={sms} />
               </div>
            </div>
         </Workspace.ItemWrapper>
      </>
   );
};

export default SMS;
