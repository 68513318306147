import { useToast } from "@chakra-ui/react";
import { ArcElement, BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from "chart.js";
import { useEffect, useMemo, useState } from "react";
import { Bar, Pie } from "react-chartjs-2";
import { useTable } from "react-table";
import { ComponentRequireRole } from "../../components/ComponentRequireRole";
import Index from "../../components/ui/DashboardIndex";
import EmptyList from "../../components/ui/EmptyList";
import { Loading } from "../../components/ui/loading";
import { ResourceLoadingError } from "../../components/ui/resource-loading-error";
import Workspace from "../../components/workspace";
import { roleMap } from "../../config/roleMapping";
import dashboardService from "../../services/dashboard-service";
import "../../styles/dashboard.css";

ChartJS.register(ArcElement, Tooltip, Legend, BarElement, CategoryScale, LinearScale, Title);

function Dashboard() {
   const [isLoading, setIsLoading] = useState(true);
   const [indexes, setIndexes] = useState([]);
   const [tables, setTables] = useState({ celebrations: [] });
   const [charts, setCharts] = useState({});
   const [fetchingError] = useState(null);
   const toast = useToast();

   // const celebrationsTableData = useMemo(() => tables.celebrations, [tables.celebrations]);
   const celebrationsTableData = useMemo(() => tables?.celebrations || [], [tables?.celebrations]);
   const celebrationsTableColumns = useMemo(
      () => [
         {
            Header: "Name",
            accessor: "fullName", // accessor is the "key" in the data
         },
         {
            Header: "Celebration",
            accessor: "celebration",
         },
         {
            Header: "Date",
            accessor: "date",
         },
         {
            Header: "Phone",
            accessor: "phoneNo",
         },
         {
            Header: "Day",
            accessor: "day",
         },
      ],
      []
   );
   const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
      columns: celebrationsTableColumns,
      data: celebrationsTableData,
   });

   const genderDistributionData = {
      labels: charts?.genderDistribution?.data?.gender,
      datasets: [
         {
            data: charts?.genderDistribution?.data?.count,
            borderWidth: 3,
            backgroundColor: ["rgb(255, 99, 132)", "rgb(54, 162, 235)", "rgb(255, 206, 86)"],
         },
      ],
   };

   const offeringMonthOnMonthData = {
      labels: charts?.offeringMonthOnMonth?.data?.month,
      datasets: [{ data: charts?.offeringMonthOnMonth?.data?.amount, backgroundColor: "rgb(30, 99, 212)" }],
   };

   useEffect(() => {
      const getDashboardIndicators = async () => {
         try {
            const fetchResult = await dashboardService.getIndicators();
            setIndexes(fetchResult.data?.metrics);
            setCharts(fetchResult.data?.charts);
            setTables(fetchResult.data?.tables);
         } catch (error) {
            toast({ status: "error", description: error.message });
         } finally {
            setIsLoading(false);
         }
      };
      getDashboardIndicators();
      return () => {
         setIndexes([]);
         setIsLoading(false);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   if (isLoading) return <Loading />;
   if (fetchingError) {
      return <ResourceLoadingError error="Looks like your dashboard is not loading" showButton={false} />;
   }

   return (
      <>
         <Workspace.TopBar heading="Dashboard" />
         <div className="grid gap-y-8 mb-8">
            <div className="indexes grid gap-3 grid-flow-dense grid-cols-[repeat(auto-fill, minmax(230px, 1fr))]">
               {indexes && indexes?.length ? indexes?.map((index, key) => <Index index={index} key={key} />) : null}
            </div>

            <ComponentRequireRole requiredRoles={[roleMap.pastoralCareDirectorate]}>
               <div className="bg-white rounded-2xl">
                  <h2 className="text-xl px-3 md:px-6 py-4 mb-0 border-secondary-local">Celebrations</h2>
                  <div className="table celebrants p-0 w-full">
                     {tables?.celebrations?.length ? (
                        <table className="grid text-[0.8rem] mb-8" {...getTableProps()}>
                           <thead>
                              {headerGroups?.map((headerGroup) => (
                                 <tr
                                    className="bg-[--clr-secondary] text-white flex md:px-4"
                                    {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers?.map((column) => (
                                       <th
                                          className="text-left py-4 px-2 first:w-full last:w-1/12 [&:nth-child(2)]:w-3/12 [&:nth-child(4)]:w-3/12 [&:nth-child(3)]:w-3/12"
                                          {...column.getHeaderProps()}>
                                          {column.render("Header")}
                                       </th>
                                    ))}
                                 </tr>
                              ))}
                           </thead>
                           <tbody {...getTableBodyProps()}>
                              {rows?.map((row) => {
                                 prepareRow(row);
                                 return (
                                    <tr className="flex hover:bg-[--bg-clr] md:px-4" {...row.getRowProps()}>
                                       {row.cells?.map((cell) => {
                                          return (
                                             <td
                                                className="first:font-semibold text-left py-4 px-2 first:w-full last:w-1/12 [&:nth-child(2)]:w-3/12 [&:nth-child(4)]:w-3/12 [&:nth-child(3)]:w-3/12 [&:nth-child(3)]:bg-[--bg-clr]"
                                                {...cell.getCellProps()}>
                                                {cell.render("Cell")}
                                             </td>
                                          );
                                       })}
                                    </tr>
                                 );
                              })}
                           </tbody>
                        </table>
                     ) : (
                        <div className="message-default p-5 text-center">
                           <EmptyList message="No celebration record found" />
                        </div>
                     )}
                  </div>
               </div>
            </ComponentRequireRole>
            <ComponentRequireRole requiredRoles={[roleMap.residentPastor]}>
               <ChartCard className="rounded-2xl">
                  <h2 className="text-xl text-center mb-8">Offering Month-on-month</h2>
                  <Bar options={{ responsive: true, plugins: { legend: { display: false } } }} data={offeringMonthOnMonthData} />
               </ChartCard>
            </ComponentRequireRole>

            <div className="flex flex-wrap justify-between">
               <ComponentRequireRole requiredRoles={[roleMap.pastoralCareDirectorate]}>
                  <ChartCard className="rounded-2xl">
                     <h2 className="text-xl text-center mb-8">Gender Distribution</h2>
                     <Pie data={genderDistributionData} />
                  </ChartCard>
               </ComponentRequireRole>
            </div>
         </div>
      </>
   );
}
export default Dashboard;

function ChartCard({ children, className = "" }) {
   return <div className={`bg-white border-secondary-local p-12 ${className}`}>{children}</div>;
}
