import { useState } from "react";
import { BiCheckCircle } from "react-icons/bi";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { Button } from "../components/ui/button/Button";
import { FieldLabel } from "../components/ui/form";
import { AuthFormCard } from "../components/ui/form/auth-form-card";
import { FormSubmitError } from "../components/ui/form/submit-error";
import { endpoints } from "../config/endpoints";
import validateSecurityForm from "../utils/validateSecurityForm";

const ResetPasswordPage = () => {
   const [formValues, setFormValues] = useState({});
   const [formErrors, setFormErrors] = useState({});
   const [successMessage, setSuccessMessage] = useState("");
   const [isSubmitting, setIsSubmitting] = useState(false);
   const [submitError, setSubmitError] = useState("");

   const params = useParams();
   const [searchParams] = useSearchParams();

   const handleChange = ({ target }) => {
      setFormValues(Object.assign({}, formValues, { [target.name]: target.value }));

      const fieldValidationResult = validateSecurityForm({
         [target.name]: target.value,
      });

      if (!fieldValidationResult === true) {
         setFormErrors(Object.assign({}, formErrors, fieldValidationResult));
      }
   };

   const handlePasswordResetSubmit = async (event) => {
      event.preventDefault();
      setIsSubmitting(true);

      if (formValues.newPassword !== formValues.confirmNewPassword) {
         setFormErrors(
            Object.assign({}, formErrors, {
               confirmNewPassword: ["Password does not match"],
            })
         );
         setIsSubmitting(false);
         return;
      }
      const options = {
         method: "PUT",
         body: JSON.stringify(formValues),
         headers: {
            "Content-Type": "application/json",
         },
      };
      const tokenID = params.tokenID;

      try {
         const url = endpoints.resetPassword + "/reset-password/" + tokenID + "?token=" + searchParams.get("token");
         const response = await fetch(url, options);
         const responseJson = await response.json();
         if (!responseJson.success) throw Error(responseJson.message);
         setSuccessMessage(responseJson.message);
         setIsSubmitting(false);
      } catch (error) {
         setSubmitError(error.message || "Oops! Something went wrong");
         setIsSubmitting(false);
      }
   };

   let currentContent;

   if (successMessage) {
      currentContent = (
         <div className="grid items-center gap-y-6 my-10">
            <BiCheckCircle className="mx-auto text-success text-7xl" />
            <p className="text-center text-secondary">{successMessage}</p>
            <div className="grid mx-auto">
               <Link to="/login">
                  <Button variant="primary">Login with new password </Button>
               </Link>
            </div>
         </div>
      );
   } else {
      currentContent = (
         <>
            <header className="my-10">
               <h1 className="text-center text-2xl font-bold">Create new password</h1>
            </header>
            <p className="text-sm mb-8">
               Password must contain at least one special character, one number, one upper case and lower case letters, and must
               be at least 8 characters long.
            </p>
            {submitError && <FormSubmitError>{submitError}</FormSubmitError>}
            <form onSubmit={handlePasswordResetSubmit}>
               <div className="mb-3">
                  <FieldLabel htmlFor="newPassword">New password</FieldLabel>
                  <input
                     className="text-[0.8rem] w-full px-3 py-4 border border-[#ced4da] rounded overflow-hidden"
                     id="newPassword"
                     type="password"
                     name="newPassword"
                     placeholder="Enter new password here"
                     value={formValues.newPassword}
                     onChange={handleChange}
                     required
                  />
                  {formErrors.newPassword &&
                     formErrors.newPassword.map((_error, key) => (
                        <small className="form-error mt-1" key={key}>
                           {_error}
                        </small>
                     ))}
               </div>
               <div className="mb-3">
                  <FieldLabel htmlFor="confirmNewPassword">Re-enter your new password</FieldLabel>
                  <input
                     className="text-[0.8rem] w-full px-3 py-4 border border-[#ced4da] rounded overflow-hidden"
                     id="confirmNewPassword"
                     type="password"
                     name="confirmNewPassword"
                     placeholder="Enter new password again"
                     value={formValues.confirmNewPassword}
                     onChange={handleChange}
                     required
                  />
                  {formErrors.confirmNewPassword &&
                     formErrors.confirmNewPassword.map((_error, key) => (
                        <small className="form-error mt-1" key={key}>
                           {_error}
                        </small>
                     ))}
               </div>

               <div className="grid mb-3">
                  <Button variant="primary" type="submit" disabled={isSubmitting}>
                     Change password
                  </Button>
               </div>
            </form>
            <div className="mt-4 text-center">
               <Link to="/login">
                  <small className="fw-600">Back to login page</small>
               </Link>
            </div>
         </>
      );
   }

   return (
      <div
         className="login flex justify-center items-center"
         style={{
            width: "100%",
            backgroundImage: `linear-gradient(to bottom, rgba(2, 149, 216, 0.9), rgba(2, 149, 216, 0.9)), url('${process.env.PUBLIC_URL}/login_bg.jpg')`,
         }}>
         <AuthFormCard>
            <div className="absolute w-full -top-12 left-0">
               <div className="p-1 w-24 aspect-square mx-auto rounded-full border border-[#f8f8f8] bg-white">
                  <img src={`${process.env.PUBLIC_URL}/hwa_logo.png`} alt="Logo" />
               </div>
            </div>
            {currentContent}
         </AuthFormCard>
      </div>
   );
};

export default ResetPasswordPage;
