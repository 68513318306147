import { endpoints } from "../config/endpoints";
import { axiosPrivateFetch } from "./fetch-service";

function submitProfileEdit({ profile }) {
  return axiosPrivateFetch.put(`${endpoints.profiles}/${profile.id}`, profile);
}

function submitPasswordChange({ passwords, id }) {
  return axiosPrivateFetch.put(`${endpoints.editPassword}/${id}`, passwords);
}

function getProfile(id) {
  return axiosPrivateFetch.get(`${endpoints.profiles}/${id}`);
}

const profileService = { getProfile, submitProfileEdit, submitPasswordChange };

export default profileService;
