import { endpoints } from "../config/endpoints";
import { axiosPrivateFetch } from "./fetch-service";

function getOfferings({ page, rows }) {
  const url = endpoints.offerings;
  const options = { params: { page, rows } };
  return axiosPrivateFetch.get(url, options);
}

function getOffering({ id }) {
  return axiosPrivateFetch.get(`${endpoints.offerings}/${id}`);
}

function submitOfferingMaintenance({ offering, editOrCreate }) {
  const options =
    editOrCreate === "edit"
      ? {
          method: "PUT",
          data: offering,
          url: `${endpoints.offerings}/${offering.id}`,
        }
      : { method: "POST", data: offering, url: endpoints.offerings };

  return axiosPrivateFetch(options);
}

const offeringService = {
  getOfferings,
  getOffering,
  submitOfferingMaintenance,
};

export default offeringService;
