export default function validateMaritalStatus(
  maritalStatus = "",
  { optional } = { optional: false }
) {
  const allowedMaritalStatus = ["SINGLE", "MARRIED", "WIDOW/WIDOWER"];
  const error = [];

  if (!optional || maritalStatus) {
    if (!allowedMaritalStatus.includes(maritalStatus.toUpperCase()))
      error.push(
        "Marital status not allowed. Status should be on of SINGLE, MARRIED or WIDOW/WIDOWER"
      );
  }

  return error.join(" ");
}
