import { Security, SecurityValidationError } from "../types";
import stripEmptyObjectProp from "./stripEmptyObjectProp";
import validateNewPassword from "./validators/validateNewPassword";

export default function validateSecurityForm(security: Security) {
  let errors = {} as SecurityValidationError;

  errors.newPassword = validateNewPassword(security.newPassword);

  if (security.newPassword === security.currentPassword) {
    errors.newPassword = "Current and new passwords cannot be the same";
  }
  if (security.newPassword !== security.confirmNewPassword) {
    errors.confirmNewPassword = "Password does not match";
  }

  return stripEmptyObjectProp(errors);
}
