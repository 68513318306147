import { BiPencil, BiTrashAlt } from "react-icons/bi";
import { Button } from "../../../components/ui/button/Button";
import { PaddedIcon } from "../../../components/ui/button/Icon";
import Workspace from "../../../components/workspace";
import { useFeatureFlag } from "../../../state/featureFlagContext";

const Permission = ({ permission, formModal, setSelected }) => {
   const { allowPermissionMaintenance } = useFeatureFlag();

   return (
      <Workspace.ItemWrapper
         onClick={() => {
            setSelected(permission);
            formModal.onOpen();
         }}>
         <div className="flex justify-between items-center px-4 py-5">
            <input type="checkbox" className="me-2 md:me-4" onClick={(e) => e.stopPropagation()} />
            <p className="permission__name mx-2 w-full font-bold overflow-hidden overflow-ellipsis" title="Permission name">
               {permission.name}
            </p>

            <div className="w-4/12 md:w-5/12">{permission.description}</div>

            {allowPermissionMaintenance ? (
               <div className=" workspace__actions flex justify-end flex-wrap w-1/12 md:w-3/12 ms-4">
                  <PaddedIcon variant="primary" title="Edit" aria-label="Edit">
                     <BiPencil className="text-lg" />
                  </PaddedIcon>
                  <Button
                     variant="danger"
                     onClick={(e) => {
                        e.stopPropagation();
                     }}>
                     <BiTrashAlt />
                  </Button>
               </div>
            ) : null}
         </div>
      </Workspace.ItemWrapper>
   );
};

export default Permission;
