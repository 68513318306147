import { AxiosRequestConfig } from "axios";
import { endpoints } from "../config/endpoints";
import { axiosPrivateFetch } from "./fetch-service";

function getUsers({ page, rows, search }) {
   return axiosPrivateFetch.get(endpoints.users, { params: { page, rows, query: search } });
}

function getUser({ id }) {
   return axiosPrivateFetch.get(`${endpoints.users}/${id}`);
}

function submitUserMaintenance({ user, editOrCreate }) {
   const options: AxiosRequestConfig =
      editOrCreate === "edit"
         ? {
              method: "PUT",
           }
         : { method: "POST" };
   options.data = user;

   const url = editOrCreate === "edit" ? `${endpoints.users}/${user.id}` : endpoints.users;

   return axiosPrivateFetch(url, options);
}

const changeUserStatusFlag = ({ newStatus, userID }) => {
   const url = `${endpoints.users}/${userID}/status/${newStatus}`;

   return axiosPrivateFetch.put(url);
};

const userService = { getUsers, getUser, submitUserMaintenance, changeUserStatusFlag };

export default userService;
