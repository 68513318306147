import { Modal, ModalBody, ModalContent, ModalOverlay } from "@chakra-ui/react";
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { Button } from "../../../components/ui/button/Button";
import { TextInput } from "../../../components/ui/form";
import { SelectInput } from "../../../components/ui/form/SelectInput";
import { CheckBox } from "../../../components/ui/form/checkbox";
import Workspace from "../../../components/workspace";
import authService from "../../../services/auth-service";
import { useBranches } from "../../../state/branchListContext";
import { sortRecordsByObjectProp } from "../../../utils/stripEmptyObjectProp";
import validateMembership from "../../../utils/validateMembership";
import memberService from "./member-service";

const MemberForm = ({ modal, id, setSelected = () => {} }) => {
   const { branches } = useBranches();
   const { getUserInfo } = authService;

   const [member, setMember] = useState({
      firstName: "",
      lastName: "",
      membershipLevel: "",
      branchCode: getUserInfo().branchCode,
      sendWelcomeNotification: true,
   });
   const [isLoading, setIsLoading] = useState(id);
   const [successNotificationText, setSuccessNotificationText] = useState("");
   const [fetchError, setFetchError] = useState("");
   const [submitError, setSubmitError] = useState();

   const heading = id ? "Edit Member" : "Add New Member";
   const controller = new AbortController();
   const signal = controller.signal;

   useEffect(() => {
      const loadMemberDetails = async () => {
         try {
            const fetchResult = await memberService.getMember(id);
            setMember(fetchResult.data);
         } catch (error) {
            setFetchError(error.message);
         } finally {
            setIsLoading(false);
         }
      };
      id && loadMemberDetails();
   }, [id]);

   const handleSubmit = async (member) => {
      setSubmitError(null);

      try {
         const submitResult = await memberService.submitMemberMaintenance({
            member,
            editOrCreate: id ? "edit" : "create",
         });
         setSuccessNotificationText(submitResult.message);
      } catch (error) {
         setSubmitError(error.message);
      }
   };

   const reset = () => {
      setSuccessNotificationText(null);
      setMember({
         firstName: "",
         lastName: "",
         membershipLevel: "",
         branchCode: getUserInfo().branchCode,
         sendWelcomeNotification: true,
      });
      setSubmitError(null);
      setSelected({});
   };

   if (isLoading) return <Workspace.LoadingModal controller={controller} heading={heading} modal={modal} />;
   if (fetchError)
      return <Workspace.FetchErrorModal modal={modal} heading={heading} errorMessage={fetchError} callback={reset} />;
   if (successNotificationText)
      return <Workspace.SuccessNotificationModal modal={modal} message={successNotificationText} callback={reset} />;

   const resetOnClose = () => {
      reset();
      modal.onClose();
   };

   return (
      <Modal isOpen={modal.isOpen} onClose={resetOnClose} size="2xl">
         <ModalOverlay />
         <ModalContent className="!rounded-2xl">
            <Workspace.ModalHeader>
               <Workspace.ModalHeaderWithCloseButton>{heading}</Workspace.ModalHeaderWithCloseButton>
            </Workspace.ModalHeader>
            <ModalBody className="grid">
               <div className="py-5 px-4">
                  <div className="flex flex-wrap items-center">
                     <div className="w-full">
                        <Formik
                           initialValues={member}
                           validate={validateMembership}
                           onSubmit={handleSubmit}
                           enableReinitialize>
                           {({ values, isSubmitting }) => (
                              <Form className="grid gap-6">
                                 <SelectInput label="Member Branch" id="branchCode" name="branchCode" required>
                                    <option value=" ">Select branch</option>
                                    {branches &&
                                       sortRecordsByObjectProp(branches, "branchCode").map((_branch, key) => (
                                          <option value={_branch.branchCode} key={key}>
                                             {`${_branch.branchCode} - ${_branch.branchName}`}
                                          </option>
                                       ))}
                                 </SelectInput>
                                 <SelectInput label="Membership" id="membershipLevel" name="membershipLevel" required>
                                    <option value="">Select membership status</option>
                                    <option value="FULL MEMBER">Full Member</option>
                                    <option value="FIRST TIMER">First Timer</option>
                                    <option value="NEW CONVERT">New Convert</option>
                                    <option value="ATTENDEE">Attendee</option>
                                 </SelectInput>
                                 {values.membershipLevel === "FULL MEMBER" && (
                                    <TextInput
                                       type="date"
                                       label="Membership Date"
                                       id="membershipDate"
                                       name="membershipDate"
                                    />
                                 )}
                                 {values.membershipLevel === "FIRST TIMER" && (
                                    <>
                                       <TextInput
                                          type="date"
                                          label="First Time Date"
                                          id="firstTimeDate"
                                          name="firstTimeDate"
                                       />
                                       <TextInput
                                          type="text"
                                          label="First Service/Event Attended (e.g Sunday Service 02-may-2022)"
                                          id="firstService"
                                          name="firstService"
                                       />
                                    </>
                                 )}
                                 {values.membershipLevel === "NEW CONVERT" && (
                                    <>
                                       <TextInput
                                          type="date"
                                          label="Conversion Date"
                                          id="newConvertConversionDate"
                                          name="newConvertConversionDate"
                                       />
                                       <TextInput
                                          type="text"
                                          label="Conversion Service/Event"
                                          id="conversionEvent"
                                          name="conversionEvent"
                                       />
                                    </>
                                 )}
                                 {values.membershipLevel === "ATTENDEE" && (
                                    <>
                                       <TextInput
                                          type="date"
                                          label="Attendee First Date"
                                          id="attendeeFirstDate"
                                          name="attendeeFirstDate"
                                       />
                                       <TextInput
                                          type="text"
                                          label="Service Attended"
                                          id="attendeeFirstService"
                                          name="attendeeFirstService"
                                       />
                                    </>
                                 )}
                                 <TextInput
                                    type="text"
                                    label="First Name"
                                    id="firstName"
                                    name="firstName"
                                    placeholder="Enter first name here"
                                    required
                                 />
                                 <TextInput
                                    type="text"
                                    label="Last Name"
                                    id="lastName"
                                    name="lastName"
                                    placeholder="Enter last name here"
                                    required
                                 />
                                 <TextInput
                                    type="phone"
                                    label={
                                       <span>
                                          Phone{" "}
                                          <small className=" font-normal text-red-600 italic">
                                             (Please add international dialling code)
                                          </small>
                                       </span>
                                    }
                                    id="phoneNo"
                                    name="phoneNo"
                                 />
                                 <TextInput
                                    type="email"
                                    label="Email"
                                    id="email"
                                    name="email"
                                    placeholder="someone@example.com"
                                 />
                                 <TextInput type="text" label="Address" id="address" name="address" />
                                 <SelectInput label="Marital Status" id="maritalStatus" name="maritalStatus">
                                    <option value="" defaultValue>
                                       Marital status
                                    </option>
                                    <option value="SINGLE">Single</option>
                                    <option value="MARRIED">Married</option>
                                    <option value="WIDOW/WIDOWER">Widow/Widower</option>
                                 </SelectInput>
                                 {values.maritalStatus === "MARRIED" && (
                                    <TextInput type="text" label="Spouse Name" id="spouseName" name="spouseName" />
                                 )}
                                 <SelectInput label="Gender" id="gender" name="gender">
                                    <option value="">Gender</option>
                                    <option value="MALE">Male</option>
                                    <option value="FEMALE">Female</option>
                                 </SelectInput>
                                 <TextInput type="date" label="Date of Birth" id="dob" name="dob" />
                                 {["FULL MEMBER"].includes(values.membershipLevel) && (
                                    <TextInput
                                       type="date"
                                       label="Wedding Anniversary"
                                       id="weddingAnniversary"
                                       name="weddingAnniversary"
                                    />
                                 )}
                                 {/* {
                           I am [a first time guest, New to the area, Just visiting
                              looking for a church home,
                      a regular attender of another church
                      ]
                      
                      Would you like to become a member of the church? Yes, No, Maybe
                      
                      Would you like to be added to the first timers WhatsApp group? Yes, No
                      
                     } */}
                                 <SelectInput
                                    label="First timer decision"
                                    id="firstTimerDecision"
                                    name="firstTimerDecision">
                                    <option value="">First timer decision</option>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                    <option value="Maybe">Maybe</option>
                                 </SelectInput>
                                 <TextInput type="text" label="Occupation" id="occupation" name="occupation" />
                                 <TextInput type="text" label="Referrer" id="inviter" name="inviter" />
                                 {!Boolean(id) && values.membershipLevel === "FIRST TIMER" && (
                                    <CheckBox
                                       type="checkbox"
                                       label="Send welcome email and/or SMS"
                                       name="sendWelcomeNotification"
                                       id="sendWelcomeNotification"
                                    />
                                 )}
                                 {submitError && (
                                    <div className="mb-5">
                                       <Workspace.FormSubmitError>{submitError}</Workspace.FormSubmitError>
                                    </div>
                                 )}

                                 <div className="mb-3 flex justify-end gap-2">
                                    <Button
                                       type="reset"
                                       variant="secondary"
                                       onClick={() => {
                                          controller.abort();
                                          resetOnClose();
                                       }}>
                                       Cancel
                                    </Button>
                                    <Button
                                       variant="primary"
                                       type="submit"
                                       className="btn btn-primary"
                                       disabled={isSubmitting}>
                                       {!Boolean(id) ? "Create member" : "Edit member"}
                                    </Button>
                                 </div>
                              </Form>
                           )}
                        </Formik>
                     </div>
                  </div>
               </div>
            </ModalBody>
         </ModalContent>
      </Modal>
   );
};

export default MemberForm;
