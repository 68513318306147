import { SimpleEntity, SimpleEntityValidationError } from "../types";
import stripEmptyObjectProp from "./stripEmptyObjectProp";
import validateEntityDescription from "./validators/validateEntityDescription";
import validateEntityName from "./validators/validateEntityName";

/**
 * @param {Object} values - role
 * @param {string} values.name - role name
 * @param {string} values.description - role description
 * @return {Object} Returns an object with keys of role properties with error or empty object when there is no error
 */

export default function validateRoleForm({ name, description }: SimpleEntity): object {
  let errors = {} as SimpleEntityValidationError;
  errors.name = validateEntityName(name, "Role");
  errors.description = validateEntityDescription(description, { optional: false, entity: "Role" });

  return stripEmptyObjectProp(errors);
}
